import { Operation } from 'apollo-link'
import { ErrorHandler, ErrorResponse } from 'apollo-link-error'
import { NextPageContext } from 'next'

import links from '@api/links'
import redirect from '@helpers/redirect'
import getLogger from '@src/Logger'
import { userOperations } from '@store/modules/User'

// See https://www.apollographql.com/docs/react/features/error-handling/
const createErrorHandler = (
  xRequestGetter: (operation: Operation) => string,
  tokenGetter: (operation: Operation) => string,
  ctx?: NextPageContext,
): ErrorHandler => (errorResponse: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = errorResponse
  const xRequestId = xRequestGetter(operation)
  const token = tokenGetter(operation)
  const headers = operation.getContext().headers
  let isInvalidTokenError = false

  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      // Decorates original error before it's passed down to Apollo Client
      // so everywhere the error is logged, it contains extra data
      decorateError(graphQLError, operation, xRequestId, headers, token)

      const { extensions } = graphQLError

      if (extensions && extensions.code && extensions.code === 'TOKEN_EXPIRED') {
        isInvalidTokenError = true
      }

      const tags = {
        graphql: 'graphqlError',
      }
      getLogger().fatal({ err: graphQLError, tags }, 'GraphQL error caught in global GraphQL handler')
    })
  }

  if (networkError) {
    // Decorates original error before it's passed down to Apollo Client
    // so everywhere the error is logged, it contains extra data
    decorateError(networkError, operation, xRequestId, headers, token)
    const tags = {
      graphql: 'networkError',
    }
    getLogger().fatal({ err: networkError, tags }, 'Network error caught in global GraphQL handler')
  }

  // API returned invalid token, do logout
  if (isInvalidTokenError) {
    handleInvalidToken(ctx)
  }
}

// tslint:disable-next-line:max-func-args
const decorateError = (error: Error, operation: Operation, xRequestId: string, headers: Object, token: string) => {
  // @ts-ignore
  error.operationName = operation.operationName
  // @ts-ignore
  error.operationVariables = operation.variables
  // @ts-ignore
  error.xRequestId = xRequestId
  // @ts-ignore
  error.httpHeaders = headers
  // @ts-ignore
  error.token = token
}

const handleInvalidToken = (ctx?: NextPageContext) => {
  userOperations.onAfterLogout(ctx)
  redirect(ctx, links.login)
}

export default createErrorHandler
