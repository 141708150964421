import { useTranslation } from '@src/i18n'
import { getDefaultLocale, findLocale, Locale, WebLanguage } from '@api/locales'

/**
 * Returns current locale object as defined by Locale type
 */
const useLocale = (): Locale => {
  const { i18n } = useTranslation()
  const webLanguage = i18n.languages[0] as WebLanguage
  // i18n init is asynchronous, return default locale until i18n loaded
  // (https://github.com/i18next/i18next/issues/948)
  return findLocale(webLanguage) || getDefaultLocale()
}

export default useLocale
