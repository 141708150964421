import gql from 'graphql-tag'

import IncomingInvite from './IncomingInviteFragment.api'

const IncomingInvitesQuery = gql`
  query IncomingInvites {
    finishedInvites @client {
      ...IncomingInvite
    }
    pendingInvites: getInvitesFixed {
      ...IncomingInvite
    }
  }

  ${IncomingInvite}
`

export default IncomingInvitesQuery
