import CircularProgress from '@material-ui/core/CircularProgress'
import MuiBackdrop from '@material-ui/core/Backdrop'
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({ palette, zIndex }: Theme) => ({
  root: {
    color: palette.common.white,
    zIndex: zIndex.appBar - 1,
  },
}))

type Props = {
  open: boolean
}

const Backdrop = ({ open }: Props) => {
  const classes = useStyles({})

  return (
    <MuiBackdrop className={classes.root} open={open}>
      <CircularProgress color='inherit' size={40} thickness={5} />
    </MuiBackdrop>
  )
}

export default Backdrop
