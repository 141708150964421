import { makeStyles } from '@material-ui/core/styles'
import { isValid, subMilliseconds } from 'date-fns'
import getConfig from 'next/config'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'url'

import { apiLinks } from '@api/links'
import TinyAlert from '@base/TinyAlert'
import { Trans } from '@src/i18n'
import getLogger from '@src/Logger'
import { now } from '@lib/withTimeSync'
import { snackbarOperations } from '@store/modules/Snackbar'
import { getItem, removeItem, setItem } from '@helpers/localStorage'
import fetch from '@helpers/fetch'
import { days, minutes } from '@helpers/interval'

import { onBeforeChange, BeforeChangeCallback } from './updateChecker/InterceptedRouter'
import getRelease, { Release } from './updateChecker/getRelease'

const { UPDATE_REFRESH } = getConfig().publicRuntimeConfig

// Update after...
export const UPDATE_INTERVAL = days(1)
// When update is available, refresh after another...
export const REFRESH_INTERVAL = minutes(30)
// Check update each...
const CHECK_INTERVAL = minutes(30)

type Props = {
  show?: boolean
}

const getLatestRelease = async (): Promise<Release | null> => {
  try {
    getLogger().info('Checking for update')
    return await fetch(apiLinks.updateChecker).then(r => r.json())
  } catch (err) {
    getLogger().warn({ err }, 'Update checker failed')
  }

  return null
}

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

export const getShouldUpdate = (fromRelease: Release, toRelease: Release, updateInterval: number) => {
  const isChanged = isReleaseChanged(fromRelease, toRelease)
  const isNewer = isReleaseNewer(fromRelease, toRelease)
  const fromBuildDate = new Date(fromRelease.buildDate)
  const shouldUpdate = isValid(fromBuildDate) && fromBuildDate <= subMilliseconds(now(), updateInterval)

  return isChanged && isNewer && shouldUpdate
}

const isReleaseChanged = (fromRelease: Release, toRelease: Release): boolean => {
  return Boolean(fromRelease.buildId && toRelease.buildId && fromRelease.buildId !== toRelease.buildId)
}

const isReleaseNewer = (fromRelease: Release, toRelease: Release): boolean => {
  const fromBuildDate = new Date(fromRelease.buildDate)
  const toBuildDate = new Date(toRelease.buildDate)
  return Boolean(
    isValid(fromBuildDate) &&
    isValid(toBuildDate) &&
    // Check time diff between versions
    fromBuildDate < toBuildDate,
  )
}

const UpdateChecker = ({ show = true }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles({})
  const latestRelease = useRef<Release>(null)
  const shouldRefresh = useRef<boolean>(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  const handleCheck = async () => {
    latestRelease.current = await getLatestRelease()
    if (!latestRelease.current) {
      return
    }

    const release = getRelease()
    const should = getShouldUpdate(release, latestRelease.current, UPDATE_INTERVAL)
    setShouldUpdate(should)
    if (should) {
      getLogger().info(
        { obj: { fromRelease: getRelease(), toRelease: latestRelease.current } },
        'New Terap.io release available',
      )
    }
  }

  const handleRouterChange: BeforeChangeCallback = (method, url, _as) => {
    // If url and as provided as an object representation,
    // we'll format them into the string version here.
    const as = typeof _as === 'object' ? format(_as) : _as

    if (UPDATE_REFRESH && shouldRefresh.current) {
      getLogger().info(
        { obj: { fromRelease: getRelease(), toRelease: latestRelease.current } },
        'Updating Terap.io using force reload',
      )
      window.location.href = as
      return false
    }

    return true
  }

  const handleUpdate = () => {
    getLogger().info({ obj: { fromRelease: getRelease(), toRelease: latestRelease.current } }, 'Updating Terap.io')
    // Store release before update so we can later display "updated" snackbar
    setItem('updateFromRelease', JSON.stringify(getRelease()))
    window.location.reload()
  }

  useEffect(() => {
    const clearRouter = onBeforeChange(handleRouterChange)
    const t = setInterval(handleCheck, CHECK_INTERVAL)

    return () => {
      clearRouter()
      clearInterval(t)
    }
  }, [])

  useEffect(() => {
    try {
      const updateFromRelease: Release = JSON.parse(getItem('updateFromRelease'))
      if (!updateFromRelease) {
        return
      }
      const updateToRelease = getRelease()
      const updated =
        isReleaseChanged(updateFromRelease, updateToRelease) && isReleaseNewer(updateFromRelease, updateToRelease)
      if (updated) {
        getLogger().info(
          { obj: { fromRelease: updateFromRelease, toRelease: updateToRelease } },
          'Terap.io successfully updated',
        )
        dispatch(snackbarOperations.open('Terap.io was updated', 'success'))
      }
    } catch { }
    removeItem('updateFromRelease')
  }, [])

  useEffect(() => {
    if (!shouldUpdate) {
      return
    }

    const t = setTimeout(() => (shouldRefresh.current = true), REFRESH_INTERVAL)
    return () => clearTimeout(t)
  }, [shouldUpdate])

  if (!shouldUpdate || !show) {
    return null
  }

  return (
    <TinyAlert>
      <Trans
        i18nKey='deployment.updateChecker.shouldUpdateWarning'
        components={[
          <span key='link' data-testid='update' className={classes.link} onClick={handleUpdate} role='link' />,
        ]}
      />
    </TinyAlert>
  )
}

export default UpdateChecker
