import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const useStyles = makeStyles(({ breakpoints, zIndex }: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: zIndex.appBar,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    [breakpoints.up('sm')]: {
      right: 10,
      bottom: 10,
      left: 'auto',
      top: 'auto',
      maxWidth: 400,
      width: 'calc(100% - 40px)',
      height: 'auto',
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.3)',
    },
  },
}))

const Container = ({ children }: Props) => {
  const classes = useStyles({})

  return <Paper className={classes.root}>{children}</Paper>
}

export default Container
