import gql from 'graphql-tag'

import Conversation from '@gql/fragments/ConversationFragment.api'
import Message from '@gql/fragments/MessageFragment.api'
import MessageUser from '@gql/fragments/MessageUserFragment.api'

import ChatConversationHeaderUser from '../conversation/ConversationHeaderUserFragment.api'

export default gql`
  query ChatConversation($conversationId: String!, $first: Int = 10) {
    currentUser: getSelf {
      ...MessageUser
    }
    conversation: findConversation(pageInfo: { first: $first }, id: $conversationId) {
      ...Conversation
      # Message list
      messages {
        ...Message
      }
      user {
        # Message list user
        ...MessageUser
        # User displayed in conversation header
        ...ChatConversationHeaderUser
      }
    }
  }

  ${Conversation}
  ${Message}
  ${MessageUser}
  ${ChatConversationHeaderUser}
`
