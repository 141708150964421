import { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Cancel from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

import Button from '@base/Button'
import Box from '@base/Box'
import Link from '@base/Link'
import { OnIncomingMessageSubscription } from '@gql/__generated__'
import usePrevious from '@hooks/usePrevious'
import ContactItem from './ContactItem'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.3)',
    position: 'fixed',
    maxWidth: 320,
    // Align to top on mobile so it doesn't collide with Intercom positioned to the bottom
    top: spacing(2.5),
    right: spacing(2.5),
    width: 'calc(100vw - 40px)',
    [breakpoints.up('sm')]: {
      // Align to bottom on tablet and desktop
      bottom: spacing(2.5),
      top: 'auto',
    },
  },
  item: {
    padding: spacing(2.5),
  },
  itemName: {
    paddingRight: spacing(2.5),
  },
}))

type Props = {
  message: OnIncomingMessageSubscription['onMessageSent']
  onClose?: () => void
}

const TRANSITION_TIMEOUT = 500

const IncomingMessageReceived = ({ message, onClose }: Props) => {
  const classes = useStyles({})
  const theme: Theme = useTheme()
  const prevMessage = usePrevious(message)
  // In / out messages to perform transition effect
  const [transitionMessages, setTransitionMessages] = useState<[OnIncomingMessageSubscription['onMessageSent'], OnIncomingMessageSubscription['onMessageSent']]>([null, null])
  const { t } = useTranslation()

  useEffect(() => {
    // Make transition when previous and current message are not equal (using shallow compare)
    if (!Object.is(prevMessage, message)) {
      setTransitionMessages([prevMessage, message])
    }
  }, [prevMessage, message])

  if (!message) return null

  return (
    // tslint:disable-next-line:react-no-unnecessary-fragment
    <>
      {transitionMessages.map((msg, i) => {
        if (!msg) {
          return null
        }

        // 0 - in, 1 - out
        const isIn = i === 1

        return (
          <Fade in={isIn} key={msg.id} timeout={TRANSITION_TIMEOUT}>
            <>
              {/*
            Do not allow Intercom to overlap MUI appbar, drawer, modal etc.
            (https://material-ui.com/customization/default-theme/)
            */}
              {/* Incoming message shown with higher z-index */}
              <Paper className={classes.root} style={{ zIndex: theme.zIndex.appBar + 1 + i }}>
                <Link
                  href={`/messages?conversationId=${msg.conversation.id}`}
                  as={`/messages/${msg.conversation.id}`}
                  onClick={onClose}
                >
                  <ContactItem
                    classes={{
                      root: classes.item,
                      name: classes.itemName,
                    }}
                    controls={
                      <Box mt={1} textAlign='right'>
                        <Button color='primary' size='small' variant='contained'>
                          Reply
                        </Button>
                      </Box>
                    }
                    isExpired={false}
                    // Always style message notification as read. So font weight is normal etc.
                    isMessageRead={true}
                    imageUrl={msg.conversation.user.image}
                    name={msg.conversation.user.firstName || msg.conversation.user.displayName}
                    message={msg.content}
                  />
                </Link>
                <Box color={theme.palette.customCol.silver} position='absolute' top={1} right={1}>
                  <IconButton aria-label={t('base.dialog.close')} color='inherit' onClick={onClose}>
                    <Cancel />
                  </IconButton>
                </Box>
              </Paper>
            </>
          </Fade>
        )
      })}
    </>
  )
}

export default IncomingMessageReceived
