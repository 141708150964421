import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { showReportDialog } from '@sentry/browser'

import Box from '@base/Box'
import Typography from '@base/Typography'
import Button from '@base/Button'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    position: 'fixed',
    width: 'calc(100% - 40px)',
    maxWidth: 398,
    bottom: 20,
    right: 20,
    zIndex: 10,
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.3)',
  },
  container: {
    padding: spacing(2),
  },
  info: {
    lineHeight: 1.25,
  },
  report: {
    color: palette.common.white,
    marginRight: 21,
    width: '100%',
    maxWidth: 152,
  },
  close: {
    color: palette.common.white,
    backgroundColor: palette.secondary.main,
    width: '100%',
    maxWidth: 152,
    '&:hover': {
      backgroundColor: darken(palette.secondary.main, 0.15),
    },
  },
}))

type Props = {
  errorEventId: string | null
}

const InviteError = ({ errorEventId }: Props) => {
  const classes = useStyles({})
  const [isClosed, setClosed] = useState(false)

  if (isClosed) {
    return null
  }

  return (
    <Paper className={classes.root}>
      <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.container}>
        <Box mt={4} mb={1} color='#52606D'>
          <Typography component='div' variant='h5' weight={600} align='center' color='inherit'>
            Call failed
          </Typography>
        </Box>
        <Box maxWidth={310} mb={2}>
          <Typography variant='body1' align='center' className={classes.info}>
            Sorry for any inconvenience caused
          </Typography>
        </Box>
        <Grid container justifyContent='center' wrap='nowrap'>
          {errorEventId && (
            <Button
              className={classes.report}
              color='primary'
              onClick={() => showReportDialog({ eventId: errorEventId })}
              variant='contained'
            >
              Report
            </Button>
          )}
          <Button className={classes.close} color='primary' onClick={() => setClosed(true)} variant='contained'>
            Close
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default InviteError
