import gql from 'graphql-tag'

import SentInvite from './SentInviteFragment.api'

const SentInvitesQuery = gql`
  query SentInvites {
    finishedInvites @client {
      ...SentInvite
    }
    pendingInvites: getInvitesFixed {
      ...SentInvite
    }
  }

  ${SentInvite}
`

export default SentInvitesQuery
