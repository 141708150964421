import gql from 'graphql-tag'

const MessageWithConversation = gql`
  fragment MessageWithConversation on Message {
    conversation {
      id
      user {
        id
        displayName
        firstName
        image
      }
    }
  }
`

export default MessageWithConversation
