import { LineSvg } from '@components/flow/Line'

export const animationActionTypes = {
  PUSH: 'animation/push',
}

export type PushAction = {
  type: typeof animationActionTypes.PUSH
  payload: {
    svgName: LineSvg
  }
}

export const push = (svgName: LineSvg): AnimationAction => ({
  type: animationActionTypes.PUSH,
  payload: {
    svgName,
  },
})

export type AnimationAction = PushAction
