import { Message } from '@gql/__generated__'

import useIncomingMessageAudio from './useIncomingMessageAudio'

type Props = {
  message: Pick<Message, 'priority'>
}

const IncomingMessageAudio = ({ message }: Props) => {
  // Using separated component because hooks updates very often
  const [lowPriorityAudio, highPriorityAudio] = useIncomingMessageAudio(message)

  return (
    <>
      {lowPriorityAudio}
      {highPriorityAudio}
    </>
  )
}

export default IncomingMessageAudio
