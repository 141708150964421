import React from 'react'
import { Offline } from 'react-detect-offline'

import Typography from '@base/Typography'
import Box from '@base/Box'
import { useTranslation } from '@src/i18n'

const OfflineBar = () => {
  const { t } = useTranslation()

  return (
    // Turn off polling fallback. Not needed for modern browsers and it provides false negatives
    // when polled URL is down.
    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
    <Offline polling={false}>
      <Box bgcolor='#dd0000' color='white' fontWeight={600} position='sticky' top={0} zIndex={1000}>
        <Typography align='center'>{t('base.offline')}</Typography>
      </Box>
    </Offline>
  )
}

export default OfflineBar
