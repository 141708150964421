import gql from 'graphql-tag'

export default gql`
  query Intercom($isLoggedIn: Boolean!, $isReferral: Boolean!, $referralUrl: String!) {
    intercomHash: getIntercomHash @include(if: $isLoggedIn)
    referralUser: findUserByReferralUrl(referralUrl: $referralUrl) @include(if: $isReferral) {
      id
    }
  }
`
