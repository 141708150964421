import { makeStyles } from '@material-ui/core'
import { addSeconds } from 'date-fns'
import getConfig from 'next/config'
import Router from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import links from '@api/links'
import Box from '@base/Box'
import Typography from '@base/Typography'
import Button from '@base/Button'
import { SentInviteFragment } from '@gql/__generated__'
import capitalize from '@helpers/capitalize'
import useCancellablePromise from '@hooks/useCancellablePromise'
import { Trans, useTranslation } from '@src/i18n'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import useIsExpired from '@hooks/useIsExpired'
import getLogger from '@src/Logger'

import Countdown from '../Countdown'
import InviteLayout from '../InviteLayout'
import Progress from '../Progress'
import { getInviteVariant, getParticipantProfile } from '../utils'

const { CALL_INVITE_EXPIRATION } = getConfig().publicRuntimeConfig

type Props = {
  invite: SentInviteFragment
  onCancel: (SentInviteFragment) => Promise<boolean>
  onClose: (SentInviteFragment) => Promise<boolean>
  onExpire: (SentInviteFragment) => Promise<boolean>
  onTryAgain: (tutorId: string, duration: number, lessonId?: string) => Promise<boolean>
}

const useStyles = makeStyles({
  cancelBtn: {
    minWidth: 150,
  },
  invite: {
    height: '100%',
    '& + $invite': {
      marginTop: 20,
    },
  },
})

const SentInvite = ({ invite, onCancel, onClose, onExpire, onTryAgain }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const cancellablePromise = useCancellablePromise()
  const [resolving, setResolving] = useState(false)
  const expiresAt = useMemo(() => addSeconds(new Date(invite.room.createdAt), CALL_INVITE_EXPIRATION).toISOString(), [
    invite.room.createdAt,
  ])
  const expireDatePassed = useIsExpired(expiresAt)
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )
  const participant = getParticipantProfile<SentInviteFragment>(invite, currentProfileIds)
  const variant = getInviteVariant(invite, false)

  const resolve = (resolveFn: () => Promise<boolean>) => {
    if (resolving) return
    setResolving(true)
    cancellablePromise(resolveFn())
      .then(() => setResolving(false))
      .catch(err => getLogger().error({ err }, 'Error when resolving sent invite'))
  }

  const handleCancel = () => {
    resolve(() => onCancel(invite))
  }

  const handleClose = () => {
    resolve(() => onClose(invite))
  }

  const handleExpire = () => {
    resolve(() => onExpire(invite))
  }

  const handleTryAgain = () => {
    const lessonId = invite.room.lesson ? invite.room.lesson.id : null
    resolve(() => onClose(invite).then(() => onTryAgain(participant.id, invite.room.duration, lessonId)))
  }

  const handleTryAnotherTutor = () => {
    resolve(() => onClose(invite).then(() => Router.push(links.tutorSearch)))
  }

  useEffect(() => {
    // Invite expired based on expiration date but on API it's still not marked as expired
    if (expireDatePassed && variant === 'sent') {
      handleExpire()
    }
  }, [expireDatePassed, variant])

  let button
  if (resolving) {
    button = <Progress />
  } else {
    switch (variant) {
      case 'expired':
        button = (
          <Box display='flex' width='100%' justifyContent='space-around' flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
            <Button onClick={handleTryAgain} variant='contained' color='primary'>
              {t('callInvite.expired.button.tryAgain')}
            </Button>
            <Button onClick={handleTryAnotherTutor} variant='contained'>
              {t('callInvite.expired.button.anotherTutor')}
            </Button>
          </Box>
        )
        break
      case 'busy':
      case 'rejected':
      case 'sent':
        button = (
          <Button
            className={classes.cancelBtn}
            color='primary'
            variant='outlined'
            onClick={variant === 'busy' || variant === 'rejected' ? handleClose : handleCancel}
            data-testid='inviteButton'
          >
            {t(`callInvite.${variant}.button`)}
          </Button>
        )
        break
      default:
        throw new Error('Unhandled invite variant')
    }
  }

  return (
    <InviteLayout
      boxProps={{ className: classes.invite, 'data-testid': `invite${capitalize(variant)}` } as any}
      button={button}
      info={
        <Typography variant='body2' align='center' data-testid='inviteInfo'>
          <Trans
            i18nKey={`callInvite.${variant}.info`}
            values={{
              name: participant ? participant.user.firstName : 'Tutor',
            }}
            components={[
              variant === 'sent' && (
                <Countdown
                  key='countdown'
                  expireDate={expiresAt}
                  textProps={{ 'data-testid': 'inviteCountdown' } as any}
                />
              ),
            ]}
          />
        </Typography>
      }
      // Student is allowed to close invite only when expired
      // With onClose = undefined we hide close button.
      onClose={variant === 'expired' ? handleClose : undefined}
      infoTitleProps={{ profile: participant }}
      title={t(`callInvite.${variant}.title`, { name: participant?.user?.firstName || 'Tutor' })}
    />
  )
}

export default SentInvite
