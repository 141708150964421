import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

import Box from '@base/Box'

const Progress = () => (
  <Box alignItems='center' display='flex' justifyContent='center' height={{ xs: 50, md: 60 }}>
    <CircularProgress size={30} thickness={5} />
  </Box>
)

export default Progress
