import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import SnackbarContent from './snacksbars/SnackbarContent'

import { snackbarOperations, snackbarSelectors } from '@src/store/modules/Snackbar'
import { useTranslation } from '@src/i18n'

type Props = {
  message: ReturnType<typeof snackbarSelectors.getMessage>
  isOpen: ReturnType<typeof snackbarSelectors.isOpen>

  close: typeof snackbarOperations.close
  processQueue: typeof snackbarOperations.processQueue
}

type StyleProps = {
  verticalOrigin: 'top' | 'bottom'
}

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  close: {
    padding: spacing(0.5),
  },
  snackbar: {
    top: (props: StyleProps) => (props.verticalOrigin === 'top' ? spacing(2) : 'unset'),
    bottom: (props: StyleProps) => (props.verticalOrigin === 'bottom' ? spacing(2) : 'unset'),
    [breakpoints.down('xs')]: {
      bottom: (props: StyleProps) => (props.verticalOrigin === 'bottom' ? spacing(12) : 'unset'),
    },
  },
}))

const Snackbars = ({ close, isOpen, message, processQueue }: Props) => {
  const classes = useStyles({ verticalOrigin: message?.options.verticalOrigin })
  const { t } = useTranslation()

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  const handleExited = () => {
    processQueue()
  }

  useEffect(() => {
    processQueue()
  }, [])

  if (!message) {
    return null
  }

  return (
    <Snackbar
      className={classes.snackbar}
      key={message.key}
      anchorOrigin={{
        vertical: message.options.verticalOrigin,
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={message.options.autoHideDuration}
      onClose={handleClose}
      onExited={handleExited}
      action={[
        <IconButton
          key='close'
          aria-label={t('flashmessages.flashmessage.btnClose')}
          color='inherit'
          className={classes.close}
          onClick={ev => handleClose(ev, null)}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    >
      <SnackbarContent message={message.message} onClose={handleClose} variant={message.variant} />
    </Snackbar>
  )
}

const mapStateToProps = state => {
  const snack = snackbarSelectors.getSelf(state)

  return {
    message: snackbarSelectors.getMessage(snack),
    isOpen: snackbarSelectors.isOpen(snack),
  }
}

const mapDispatchToProps = {
  close: snackbarOperations.close,
  processQueue: snackbarOperations.processQueue,
}

const SnackbarsConnected = connect(mapStateToProps, mapDispatchToProps)(Snackbars)

export default SnackbarsConnected
