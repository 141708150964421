/**
 * Turn error that is not an instance of Error class into new Error()
 */
const toError = (err: any): Error => {
  // 'I am an error message'
  if (typeof err === 'string') {
    return new Error(err)
  }
  if (typeof err === 'object' && err !== null) {
    // new Error('I am an error message')
    if (err instanceof Error) {
      return err
    }

    // { message: 'I am an error message' }
    const fakeError = new Error(err.message)
    for (const prop in err) {
      if (!err.hasOwnProperty(prop)) continue
      fakeError[prop] = err[prop]
    }
    // tslint:disable-next-line:no-console
    console.error('bad error', err)
    return fakeError
  }
  // tslint:disable-next-line:no-console
  console.error('bad error', err)
  // Unhandled error type
  return new Error(JSON.stringify(err))
}

export default toError
