import gql from 'graphql-tag'

const ChatConversationHeaderTutorFragment = gql`
  fragment ChatConversationHeaderTutor on Tutor {
    id
    busy {
      isBusy
      until
      flag
    }
  }
`

export default ChatConversationHeaderTutorFragment
