import gql from 'graphql-tag'

import AttachmentCallEnded from './AttachmentCallEndedFragment.api'
import AttachmentScheduledCall from './AttachmentScheduledCallFragment.api'

const Message = gql`
  fragment Message on Message {
    id
    fromUserId
    content
    roomId
    sent
    read
    priority
    attachment {
      ...AttachmentCallEnded
      ...AttachmentScheduledCall
    }
    conversation {
      id
      members {
        userId
      }
      user {
        id
      }
    }
  }

  ${AttachmentCallEnded}
  ${AttachmentScheduledCall}
`

export default Message
