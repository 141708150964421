import { makeStyles, Theme } from '@material-ui/core'
import Tab, { TabProps } from '@material-ui/core/Tab'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import SchoolIcon from '@material-ui/icons/School'
import Tooltip from '@material-ui/core/Tooltip'
import { addSeconds } from 'date-fns'
import getConfig from 'next/config'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Box from '@base/Box'
import Typography from '@base/Typography'
import { IncomingInviteFragment } from '@gql/__generated__'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import { useTranslation } from '@src/i18n'

import { getInviteVariant, getParticipantProfile } from '../utils'
import Countdown from '../Countdown'

const { CALL_INVITE_EXPIRATION } = getConfig().publicRuntimeConfig

type Props = TabProps & {
  invite: IncomingInviteFragment
}

type StyleProps = {
  hasIcon: boolean
  missed: boolean
  selected: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette }) => {
  const getTitleColor = props => {
    if (!props.selected) {
      return palette.text.secondary
    }
    return props.missed ? palette.error.main : palette.primary.main
  }
  const getSubtitleColor = props => {
    if (!props.selected) {
      return palette.text.secondary
    }
    return props.missed ? palette.error.main : palette.text.primary
  }

  return {
    root: {
      paddingRight: props => (props.hasIcon ? 32 : undefined),
      minWidth: 130,
      maxWidth: 220,
    },
    selected: {
      background: palette.common.white,
    },
    icon: {
      color: getTitleColor,
    },
    title: {
      color: getTitleColor,
    },
    subtitle: {
      color: getSubtitleColor,
    },
  }
})

const IncomingTab = ({ invite, ...props }: Props) => {
  const { t } = useTranslation()
  const variant = getInviteVariant(invite, true)
  const missed = variant === 'expired' || variant === 'cancelled'
  const expiresAt = useMemo(() => addSeconds(new Date(invite.room.createdAt), CALL_INVITE_EXPIRATION).toISOString(), [
    invite.room.createdAt,
  ])
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )
  const participant = getParticipantProfile<IncomingInviteFragment>(invite, currentProfileIds)
  const lesson = invite.room.lesson
  const classes = useStyles({ missed, selected: props.selected, hasIcon: !!lesson })

  let icon

  if (lesson) {
    icon = (
      <Tooltip title={t('incomingCall.package.title')}>
        <SchoolIcon className={classes.icon} fontSize='small' />
      </Tooltip>
    )
  } else if (invite.room.isScheduled) {
    icon = (
      <Tooltip title={t('incomingCall.scheduledCall.title')}>
        <CalendarTodayIcon className={classes.icon} fontSize='small' />
      </Tooltip>
    )
  }

  return (
    <Tab
      classes={{ root: classes.root, selected: classes.selected }}
      label={
        <>
          {icon && (
            <Box component='span' position='absolute' left={5} top={5}>
              {icon}
            </Box>
          )}
          <Box maxWidth='100%'>
            <Typography className={classes.title} noWrap={true} variant='body2' weight={600}>
              {participant ? participant.user.firstName || participant.user.displayName : 'Student'}
            </Typography>
          </Box>
          {variant === 'received' && (
            <Typography className={classes.subtitle} variant='caption'>
              <Countdown key='countdown' expireDate={expiresAt} />
            </Typography>
          )}
          {missed && (
            <Typography className={classes.subtitle} variant='caption'>
              {t(`incomingCall.${variant}.title`)}
            </Typography>
          )}
        </>
      }
      {...props}
    />
  )
}

export default IncomingTab
