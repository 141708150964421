import gql from 'graphql-tag'

const typeDefs = gql`
  extend type Query {
    savedCurrency: String
    finishedInvites: [Invite!]!
  }
`

export default typeDefs
