import { ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'

import Typography from '@base/Typography'
import Box from '@base/Box'
import Avatar from '@base/Avatar'
import RatingSimple from '@base/RatingSimple'
import {
  InfoTitleStudentFragment,
  InfoTitleTutorFragment,
} from '@gql/__generated__'

import { InfoTitleStudent, InfoTitleTutor } from './infoTitle/InfoTitleProfileFragment.api'

type Props = {
  profile: InfoTitleStudentFragment | InfoTitleTutorFragment
  showRating?: boolean
  theme?: 'dark' | 'light'
  size?: number
  hidePhotoXS?: boolean
  rating?: ReactNode
}

const InfoTitle = ({ profile, showRating = true, theme = 'light', size = 92, hidePhotoXS = false, rating }: Props) => {
  const { user } = profile
  const { image, firstName, displayName, countryCode } = user

  return (
    <div data-testid='userProfile'>
      <Grid container alignItems='center' wrap='nowrap'>
        <Box {...(hidePhotoXS && { display: { xs: 'none', sm: 'block' } })}>
          <Avatar alt={firstName || displayName} src={image} size={size} marginRight={20} data-testid='profilePhoto' />
        </Box>
        <div>
          <Grid container alignItems='center' wrap='nowrap'>
            <Box ml={countryCode ? 1.25 : 0}>
              <Typography
                component='div'
                variant='h6'
                weight={600}
                color='textSecondary'
                kind={theme}
                data-testid='firstName'
              >
                {firstName || displayName}
              </Typography>
            </Box>
          </Grid>
          {showRating && <Box mt={0.25}>{rating ? rating : <RatingSimple value={profile.rating} />}</Box>}
        </div>
      </Grid>
    </div>
  )
}

export default InfoTitle

export { InfoTitleStudent, InfoTitleTutor }
