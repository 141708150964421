import { addSeconds } from 'date-fns'
import getConfig from 'next/config'
import Head from 'next/head'
import React, { useMemo } from 'react'

import { IncomingInviteFragment } from '@gql/__generated__'
import useCountdown from '@hooks/useCountdown'
import { formatInterval } from '@helpers/dateTime'
import { useTranslation } from '@src/i18n'

import { getOldestInvite } from '../utils'

const { CALL_INVITE_EXPIRATION } = getConfig().publicRuntimeConfig

type Props = {
  pendingInvites: IncomingInviteFragment[]
}

const InvitesHead = ({ pendingInvites }: Props) => {
  const oldestInvite = getOldestInvite(pendingInvites)

  if (!oldestInvite) {
    return null
  }

  return <InvitesHeadImpl invite={oldestInvite} />
}

type InviteHeadProps = {
  invite: IncomingInviteFragment
}

const InvitesHeadImpl = ({ invite }: InviteHeadProps) => {
  const expiresAt = useMemo(() => addSeconds(new Date(invite.room.createdAt), CALL_INVITE_EXPIRATION).toISOString(), [
    invite.room.createdAt,
  ])
  const countdown = useCountdown(expiresAt)
  const { t } = useTranslation()

  return (
    <Head>
      <title>
        {formatInterval(countdown)} {t('incomingCall.head')}
      </title>
    </Head>
  )
}

export default InvitesHead
