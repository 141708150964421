import gql from 'graphql-tag'

import Message from '@gql/fragments/MessageFragment.api'
import MessageWithConversation from '@gql/fragments/MessageWithConversationFragment.api'

const ON_INCOMING_MESSAGE = gql`
  subscription onIncomingMessage {
    onMessageSent {
      ...Message
      ...MessageWithConversation
    }
  }

  ${Message}
  ${MessageWithConversation}
`

export default ON_INCOMING_MESSAGE
