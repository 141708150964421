import { Component } from 'react'
import Router from 'next/router'

import analytics from '../Analytics'

const withAnalytics = () => App => {
  class WithAnalytics extends Component {
    static getInitialProps

    componentDidMount() {
      Router.events.on('routeChangeComplete', () => {
        analytics.pushPageview()
      })
    }

    render() {
      return <App {...this.props} />
    }
  }

  if (App.getInitialProps) {
    WithAnalytics.getInitialProps = App.getInitialProps
  }

  return WithAnalytics
}

export default withAnalytics
