export type Release = {
  buildId: string
  buildDate: string
}

const getRelease = (): Release => ({
  buildId: process.env.BUILD_ID,
  buildDate: process.env.BUILD_DATE,
})

export default getRelease
