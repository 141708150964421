import gql from 'graphql-tag'

export default gql`
  query IntercomUser {
    user: getSelf {
      id
      firstName
      displayName
      lastName
      image
    }
  }
`
