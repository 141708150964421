import MuiListItem, { ListItemProps as MuiListItemProps } from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React, { ReactNode } from 'react'
import Typography from '@base/Typography'
import Link, { Props as LinkProps } from '@base/Link'
import Box from '@base/Box'

export type ListItemProps = {
  button?: boolean
  emphasized?: boolean
  link?: LinkProps
  ListItemProps?: MuiListItemProps
  onClick?: () => void
  selected?: boolean
  title: ReactNode
  beforeTitle?: ReactNode
  afterTitle?: ReactNode
  testId?: string
}

const ListItem = ({
  beforeTitle,
  button,
  emphasized,
  link,
  ListItemProps: ListItemProps_,
  onClick,
  selected,
  title,
  afterTitle,
  testId,
}: ListItemProps) => {
  const handleClick = () => {
    onClick && onClick()
  }
  const dataTestId = testId || 'linkItem'

  const item = (
    // probably cannot be fixed: https://github.com/mui-org/material-ui/issues/14971
    <MuiListItem button={Boolean(button || link) as any} onClick={handleClick} selected={selected} {...ListItemProps_}>
      {beforeTitle}
      <ListItemText data-testid={dataTestId}>
        <Typography
          color={selected ? 'primary' : undefined}
          variant='body2'
          weight={emphasized || selected ? 600 : 400}
        >
          {title}
        </Typography>
      </ListItemText>
      <Box ml={1}>{afterTitle}</Box>
    </MuiListItem>
  )

  return link ? (
    <Link data-testid={dataTestId} {...link}>
      {item}
    </Link>
  ) : (
    item
  )
}

export default ListItem
