import {
  ApolloClient,
  NormalizedCacheObject,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher,
  StoreObject,
} from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import cuid from 'cuid'
import { ApolloLink, Operation, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import loggerLink from 'apollo-link-logger'
import { RetryLink } from 'apollo-link-retry'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { NextPageContext } from 'next'
import getConfig from 'next/config'

import { httpHeaderToToken, tokenToHttpHeader } from '@helpers/auth'
import fetch from '@helpers/fetch'
import toError from '@helpers/toError'
import introspectionQueryResultData from '@gql/__FragmentTypes__.json'
import getLogger from '@src/Logger'
import { Token } from '@store/modules/User'

import typeDefs from './initApollo/typeDefs'
import createErrorHandler from './initApollo/graphqlErrorHandler'
import LoggerAwareInMemoryCache from './initApollo/LoggerAwareInMemoryCache'

let apolloClient = null

const { GATEWAY_HOST, APOLLO_LOG, WS_HOST } = getConfig().publicRuntimeConfig

type Options = {
  ctx?: NextPageContext
  getToken: () => Token | null
}

// Initial local state
const initialData = {
  getInvitesFixed: [],
  savedCurrency: null,
  finishedInvites: [],
}

const getFetchOptions = () => {
  let options = {}
  if (process.browser) {
    const abortController = new AbortController()
    options = { signal: abortController?.signal }
  }

  return options
}

/**
 * This function builds up objects IDs for apollo cache.
 * 
 * It needs to perform special treatment of any entity which nests a Lesson because it might
 * include the `lessonUsage` field which is independent of the Lesson id and will be cached incorrectly
 * if not accounted for here.
 */
function dataIdFromObject(obj: Readonly<StoreObject>) {
  if ('__ref' in obj) {
    return obj['__ref'] as string
  }

  let id = defaultDataIdFromObject(obj)

  switch (obj.__typename) {
    case 'Lesson': {
      if ('lessonUsage' in obj) {
        id = `${id}:${obj['lessonUsage']}`
      }
    } break;
    case 'Package': {
      if ('lessons' in obj) {
        const innerIds = (obj.lessons as any[]).map(l => dataIdFromObject(l)).join(',')
        id = `${id}[${innerIds}]`
      }
    } break;
    case 'Invite': {
      id = `${obj.__typename}:${(obj as any).roomId}`
    } break;
  }

  return id;
}

function create(initialState: NormalizedCacheObject, { ctx, getToken }: Options): ApolloClient<any> {
  let connectionToken

  const uploadLink = createUploadLink({
    fetch,
    uri: GATEWAY_HOST,
    credentials: 'same-origin',
    fetchOptions: { ...getFetchOptions() },
  })
  let connectionLink = uploadLink

  if (process.browser) {
    const wsLink = new WebSocketLink({
      uri: WS_HOST,
      options: {
        reconnect: true,
        lazy: true,
        connectionCallback: (err: any) => {
          if (err) {
            const error = toError(err)
            getLogger().error({ err: error }, 'Websocket connection error')
          }
        },
        connectionParams: () => {
          connectionToken = getToken()

          if (!connectionToken) {
            throw new Error('dont connect websocket if you dont have any token dammit')
            // best would be to just reload app
          }
          return {
            authToken: connectionToken,
          }
        },
        timeout: 10000 + 1000,
      },
    })

    connectionLink = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      uploadLink,
    )
  }

  /**
   * Attaches authorization headers to the operation.
   * When operation is retried (on fail), this link isn't executed, so that operation retries are identical.
   * (auth token must be the same on every retry)
   */
  const authLink = setContext((operation, { headers }) => {
    const token = getToken()
    return {
      headers: {
        ...headers,
        authorization: tokenToHttpHeader(token),
      },
    }
  })

  /**
   * Attaches xRequestId headers to the operation.
   * When operation is retried (on fail), this link is re-executed, so each request has unique ID
   */
  const requestIdLink = setContext((operation, { headers }) => {
    const requestId = cuid()
    return {
      headers: {
        ...headers,
        'X-RequestId': requestId,
      },
    }
  })

  const errorLink = onError(
    createErrorHandler(
      (operation: Operation) => {
        const context = operation.getContext()
        return context.headers['X-RequestId']
      },
      (operation: Operation) => {
        const context = operation.getContext()
        return httpHeaderToToken(context.headers['authorization'])
      },
      ctx,
    ),
  )

  const retryLink = new RetryLink({
    attempts: {
      max: 5,
      retryIf: (error, operation) => {
        const definitions = operation.query.definitions
        const isMutation = !!definitions.filter(
          definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation',
        ).length
        return !isMutation && !!error
      },
    },
  })

  // See https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  const cache = new LoggerAwareInMemoryCache({
    // See https://www.apollographql.com/docs/react/advanced/caching/#cache-redirects-with-cacheredirects
    cacheRedirects: {
      Query: {
        getTutor: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Tutor', id: args.id }),
      },
    },
    dataIdFromObject,
    fragmentMatcher,
  })

  /**
   * Mind that links on the right side of the `RetryLink` are re-executed on each retry
   */
  const links = [errorLink, authLink, retryLink, requestIdLink, connectionLink]

  if (APOLLO_LOG) {
    links.unshift(loggerLink)
  }

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const client = new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from(links),
    cache: cache.restore(initialState || {}),
    resolvers: {},
    // Using typeDefs so Apollo devtools introspection knows the local fields
    typeDefs,
  })
  cache.writeData({ data: initialData })
  client.onResetStore(async () => cache.writeData({ data: initialData }))
  return client
}

export default function initApollo(initialState: NormalizedCacheObject, options: Options): ApolloClient<any> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options)
  }

  return apolloClient
}
