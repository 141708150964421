import { useApolloClient, useQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import { DataProxy } from 'apollo-cache'
import NoSsr from '@material-ui/core/NoSsr'
import getConfig from 'next/config'
import Router from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { call } from '@api/links'
import InviteError from '@components/invite/InviteError'
import ErrorBoundary from '@layouts/ErrorBoundary'
import {
  CallInviteStatus,
  SentInviteFragment,
  SentInvitesQuery,
} from '@gql/__generated__'
import {
  SentInvitesCancelMutation,
  SentInvitesCancelMutationVariables,
  SentInvitesSendMutation,
  SentInvitesSendMutationVariables,
} from '@gql/__generated__'
import { SentInvitesOnConfirmedSubscription, SentInvitesOnRejectedSubscription } from '@gql/__generated__'
import { apiErrorToCode } from '@helpers/api'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import getLogger from '@src/Logger'
import analytics from '@src/Analytics'
import { useTranslation } from '@src/i18n'
import { snackbarOperations } from '@store/modules/Snackbar'

import CANCEL from './sentInvites/CancelMutation.api'
import SEND from './sentInvites/SendMutation.api'
import ON_CONFIRMED from './sentInvites/OnConfirmedSubscription.api'
import ON_REJECTED from './sentInvites/OnRejectedSubscription.api'
import QUERY from './sentInvites/Query.api'
import SENT_INVITE, { FragmentName as SENT_INVITE_NAME } from './sentInvites/SentInviteFragment.api'
import SentInvite from './sentInvites/SentInvite'
import Backdrop from './Backdrop'
import Container from './Container'
import SendInviteError from './SendInviteError'
import {
  readInviteById,
  setInviteFinished,
  setInvitePending,
  setInviteResolved,
  setInviteStatus,
} from './InvitesResolver'
import { getParticipantProfile, sortInvitesByCreatedAt } from './utils'
import useGoToCall from './useGoToCall'

const { INVITE_NAVIGATION_BLOCKER } = getConfig().publicRuntimeConfig

const SentInvites = () => {
  const isStudent = useSelector<AppState, boolean>(state => userSelectors.isStudent(userSelectors.getSelf(state)))

  if (!isStudent) {
    return null
  }

  return (
    // prevent websocket initialization on server
    <NoSsr>
      <ErrorBoundary component={InviteError}>
        <SentInvitesImpl />
      </ErrorBoundary>
    </NoSsr>
  )
}

const SentInvitesImpl = () => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const goToCall = useGoToCall()
  const { t } = useTranslation()
  const { data: { finishedInvites = [], pendingInvites = [] } = {} } = useQuery<SentInvitesQuery>(QUERY, {
    // @TODO Move student's pendingInvites to @client cache so they are not synced with API, then remove fetch-policy
    fetchPolicy: 'cache-only',
  })
  const [confirmedInvite, setConfirmedInvite] = useState<SentInviteFragment>(null)
  // Using same data structure for sent and incoming invites. Although only 1 sent invite can appear.
  const invites = useMemo(
    () => sortInvitesByCreatedAt<SentInviteFragment>([...pendingInvites, ...finishedInvites]),
    [pendingInvites, finishedInvites],
  )
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )
  const [error, setError] = useState<string>(null)

  const handleGoToCall = (invite: SentInviteFragment) => {
    goToCall(invite)
      .then(() => setConfirmedInvite(null))
      .catch(err => getLogger().warn({ err }, 'Navigation to call failed'))
  }

  const handleCancelInvite = (invite: SentInviteFragment): Promise<boolean> => {
    const participant = getParticipantProfile(invite, currentProfileIds)
    return cancelInvite({
      variables: {
        profileId: participant.id,
        roomId: invite.roomId,
        expired: false,
      },
      update: (proxy, mutationResult) => {
        // Mutation result sets this Invite field:
        // invite.room.callInviteStatus = "InviteCanceled"
        setInviteResolved(proxy, invite.roomId)

        getLogger().info(
          { obj: { mutationResult, roomId: invite.roomId } },
          'Invite cancelled - mutation cancelInviteFixed()',
        )

        analytics.pushEvent('invite/cancelled')
      },
    })
      .then(() => true)
      .catch(err => {
        getLogger().error(err, 'Invite cancel failed')
        dispatch(snackbarOperations.open(t('base.error.post'), 'error'))
        return false
      })
  }

  const handleConfirmedInvite = (proxy: DataProxy, invite: SentInviteFragment) => {
    getLogger().info({ obj: { invite } }, 'Invite confirmed - subscription onInviteConfirmed()')

    if (!invite) {
      return
    }

    // Remove invite from pending invites
    setInviteResolved(proxy, invite.roomId)
    setConfirmedInvite(invite)

    // Mutation result sets this Invite field:
    // room.callInviteStatus = "InviteAccepted"

    // Go to call without navigation blocker (when disabled)
    !INVITE_NAVIGATION_BLOCKER && handleGoToCall(invite)
  }

  const handleCloseInvite = (invite: SentInviteFragment): Promise<boolean> => {
    setInviteResolved(client, invite.roomId)
    return Promise.resolve(true)
  }

  const handleExpireInvite = (invite: SentInviteFragment): Promise<boolean> => {
    const participant = getParticipantProfile(invite, currentProfileIds)

    // We don't care about the mutation result.
    // Invite is marked expired on BE after given interval so everything is in-sync.
    setInviteStatus(client, invite.roomId, CallInviteStatus.InviteExpired)
    setInviteFinished(client, invite.roomId)

    return cancelInvite({
      variables: {
        profileId: participant.id,
        roomId: invite.roomId,
        expired: true,
      },
      update: (proxy, mutationResult) => {
        // Mutation result sets this Invite field:
        // invite.room.callInviteStatus = "InviteExpired"

        getLogger().info(
          { obj: { mutationResult, roomId: invite.roomId } },
          'Invite expired - mutation cancelInviteFixed()',
        )

        analytics.pushEvent('invite/expired')
      },
    })
      .then(() => true)
      .catch(err => {
        getLogger().error(err, 'Invite expire failed - mutation cancelInviteFixed()')
        return false
      })
  }

  const handleTryAgain = (tutorId: string, duration: number, lessonId?: string): Promise<boolean> => {
    return sendInvite({
      variables: {
        tutorId,
        duration,
        lessonId,
      },
      update: (proxy, mutationResult) => {
        const {
          data: { invite: sentInvite },
        } = mutationResult
        const participant = getParticipantProfile(sentInvite, currentProfileIds)

        setInvitePending(proxy, sentInvite.roomId)
        analytics.pushEvent('invite/sent', { tutorName: participant.user.displayName })
      },
    })
      .then(() => true)
      .catch(err => {
        setError(apiErrorToCode(err) || 'UNKNOWN_ERROR')
        return false
      })
  }

  const handleRejectedInvite = (proxy: DataProxy, invite: SentInviteFragment) => {
    getLogger().info({ obj: { invite } }, 'Invite rejected - subscription onInviteRejected()')

    if (!invite) {
      return
    }

    // Mutation result sets this Invite field:
    // invite.room.callInviteStatus = "InviteExpired" / "InviteRejected"
    setInviteFinished(proxy, invite.roomId)
  }

  const [cancelInvite] = useMutation<SentInvitesCancelMutation, SentInvitesCancelMutationVariables>(CANCEL)
  const [sendInvite] = useMutation<SentInvitesSendMutation, SentInvitesSendMutationVariables>(SEND)

  useEffect(() => {
    pendingInvites.forEach(pendingInvite => {
      const callLink = call(pendingInvite.roomId)
      Router.prefetch(callLink.href, callLink.as).catch(err => getLogger().warn({ err }, 'Browser prefetch failed'))
    })
  }, [pendingInvites.length])

  useEffect(() => {
    if (invites.length <= 1) {
      return
    }

    getLogger().error(
      { obj: { pendingInvites, finishedInvites, invites } },
      'Multiple sent invites appeared for student',
    )
  }, [invites.length])

  // Go to call right after UI displays navigation blocker (when enabled)
  // (displays navigation-blocking backdrop)
  useEffect(() => {
    INVITE_NAVIGATION_BLOCKER && confirmedInvite && handleGoToCall(confirmedInvite)
  }, [confirmedInvite?.id])

  useSubscription<SentInvitesOnConfirmedSubscription>(ON_CONFIRMED, {
    onSubscriptionData: ({ client: ownClient, subscriptionData }) => {
      const { data: { confirmedInvite: confInvite = null } = {} } = subscriptionData
      // We receive only partial invite data in subscription, need to read full entity from cache
      const invite = readInviteById<SentInviteFragment>(ownClient, confInvite.roomId, SENT_INVITE, SENT_INVITE_NAME)
      handleConfirmedInvite(ownClient, invite)
    },
  })

  useSubscription<SentInvitesOnRejectedSubscription>(ON_REJECTED, {
    onSubscriptionData: ({ client: ownClient, subscriptionData }) => {
      const { data: { rejectedInvite = null } = {} } = subscriptionData
      // We receive only partial invite data in subscription, need to read full entity from cache
      const invite = readInviteById<SentInviteFragment>(ownClient, rejectedInvite.roomId, SENT_INVITE, SENT_INVITE_NAME)
      handleRejectedInvite(ownClient, invite)
    },
  })

  return (
    <>
      {error && <SendInviteError open={true} onClose={() => setError(null)} error={error} />}
      {invites.length > 0 && (
        <Container>
          {invites.map(invite => (
            <SentInvite
              key={invite.roomId}
              invite={invite}
              onCancel={handleCancelInvite}
              onClose={handleCloseInvite}
              onExpire={handleExpireInvite}
              onTryAgain={handleTryAgain}
            />
          ))}
        </Container>
      )}
      {INVITE_NAVIGATION_BLOCKER && <Backdrop open={!!confirmedInvite} />}
    </>
  )
}

export default SentInvites
