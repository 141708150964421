import { useMutation } from '@apollo/react-hooks'
import { Field, Formik, Form } from 'formik'
import { makeStyles, Theme } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import memoize from 'lodash/memoize'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import Box from '@base/Box'
import ProgressButton from '@base/ProgressButton'
import FormikField from '@base/FormikField'
import Button from '@base/Button'
import Dialog from '@base/Dialog'
import { IncomingInviteFragment } from '@gql/__generated__'
import {
  ReplyMessageModalCreateMutation,
  ReplyMessageModalCreateMutationVariables,
  ReplyMessageModalSendMutation,
  ReplyMessageModalSendMutationVariables,
  WebLanguage,
} from '@gql/__generated__'
import CONVERSATION_QUERY from '@components/chat/chat/ConversationQuery.api'
import { getParticipantProfile } from '@components/invite/utils'
import { snackbarOperations } from '@store/modules/Snackbar'
import getLogger from '@src/Logger'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import { useTranslation } from '@src/i18n'

import SEND_MESSAGE_MUTATION from './replyMessageModal/SendMessageMutation.api'
import CREATE_CONVERSATION_MUTATION from './replyMessageModal/CreateConversationMutation.api'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  button: {
    minWidth: 150,
  },
  paper: {
    width: '100vh',
  },
  dialogContent: {
    paddingBottom: spacing(2),
  },
}))

type Props = {
  invite: IncomingInviteFragment
  onClose?: () => void
  onSubmit?: (values: Values) => void
}

type Values = {
  message: string
}

const initialValues = {
  message: '',
}

const MAX_MESSAGE_LENGTH = 280

export const createSchema = memoize(
  (webLanguage: WebLanguage, t) =>
    Yup.object().shape({
      message: Yup.string()
        .required(t('incomingCall.replyMessageModal.validation.message.required'))
        .max(
          MAX_MESSAGE_LENGTH,
          t('incomingCall.replyMessageModal.validation.message.max', { count: MAX_MESSAGE_LENGTH }),
        ),
    }),
  // Memoize using webLanguage as cache key
  (webLanguage: WebLanguage) => webLanguage,
)

const ReplyMessageModal = ({ invite, onClose, onSubmit }: Props) => {
  const classes = useStyles({})
  const { i18n, t } = useTranslation()
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )

  const [createConversation] = useMutation<ReplyMessageModalCreateMutation, ReplyMessageModalCreateMutationVariables>(
    CREATE_CONVERSATION_MUTATION,
  )
  const [sendMessage] = useMutation<ReplyMessageModalSendMutation, ReplyMessageModalSendMutationVariables>(SEND_MESSAGE_MUTATION)
  const dispatch = useDispatch()

  const handleSend = async (values: Values, actions) => {
    onSubmit && onSubmit(values)
    actions.setSubmitting(true)

    try {
      const participant = getParticipantProfile<IncomingInviteFragment>(invite, currentProfileIds)
      const participantUserId = participant.user.id

      const {
        data: { conversation },
      } = await createConversation({
        variables: {
          conversationInput: {
            partnerUserId: participantUserId,
            // Create permanent conversation
            isCall: true,
          },
        },
      })

      await sendMessage({
        variables: {
          message: {
            conversationId: conversation.id,
            content: values.message,
            toUserId: participantUserId,
          },
        },
        // Refetch given chat conversation, so the new message appears there
        refetchQueries: [
          {
            query: CONVERSATION_QUERY,
            variables: {
              first: 50,
              conversationId: conversation.id,
            },
          },
        ],
      })
      actions.setSubmitting(false)
      dispatch(snackbarOperations.open(t('incomingCall.replyMessageModal.submit.success'), 'success'))
      onClose && onClose()
    } catch (err) {
      actions.setSubmitting(false)
      dispatch(snackbarOperations.open(t('incomingCall.replyMessageModal.submit.error'), 'error'))
      getLogger().error({ err }, 'Error when sending "invite reply" message')
    }
  }

  return (
    <Dialog
      open
      classes={{ paper: classes.paper }}
      disableBackdropClick={true}
      maxWidth='sm'
      onClose={onClose}
      aria-labelledby={t('incomingCall.replyMessageModal.title')}
    >
      <DialogTitle>{t('incomingCall.replyMessageModal.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik<Values>
          initialValues={initialValues}
          onSubmit={handleSend}
          validationSchema={createSchema(i18n.languages[0] as WebLanguage, t)}
          render={({ isSubmitting, values }) => (
            <Form method='post' noValidate={true}>
              <Box mb={3}>
                <Field
                  autoFocus={true}
                  component={FormikField}
                  helperText={`${values.message.length} / ${MAX_MESSAGE_LENGTH}`}
                  name='message'
                  rows={2}
                  rowsMax={4}
                  placeholder={t('incomingCall.replyMessageModal.fields.message.placeholder')}
                  multiline
                  variant='outlined'
                  fullWidth
                />
              </Box>
              <Box textAlign='right'>
                <Button className={classes.button} onClick={onClose} size='small' variant='contained'>
                  {t('incomingCall.replyMessageModal.btnCancel')}
                </Button>
                <Box display='inline-block' ml={2}>
                  <ProgressButton
                    className={classes.button}
                    data-testid='submitBtn'
                    color='primary'
                    inProgress={isSubmitting}
                    size='small'
                    variant='contained'
                  >
                    {t('incomingCall.replyMessageModal.btnSend')}
                  </ProgressButton>
                </Box>
              </Box>
            </Form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ReplyMessageModal

export { CREATE_CONVERSATION_MUTATION, SEND_MESSAGE_MUTATION, CONVERSATION_QUERY }
