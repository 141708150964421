import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AccessTime from '@material-ui/icons/AccessTime'
import LocalCafe from '@material-ui/icons/LocalCafe'
import Message from '@material-ui/icons/Message'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { ClassNameMap } from '@material-ui/styles/withStyles/withStyles'
import React, { ReactNode, ReactElement, useState } from 'react'

import Box from '@base/Box'
import Button from '@base/Button'
import ListItem from '@base/ListItem'
import PopoverContent from '@base/PopoverContent'
import { RejectReason } from '@gql/__generated__'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles(({ palette }: Theme) => ({
  button: {
    color: palette.common.white,
    backgroundColor: palette.secondary.main,
    '&:hover': {
      backgroundColor: darken(palette.secondary.main, 0.15),
    },
  },
}))

type Props = {
  classNames?: Partial<ClassNameMap<'button'>>
  children: ReactNode
  onReject?: (reason: RejectReason) => void
}

const RejectButton = ({ classNames = {}, children, onReject }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = ev => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleReject = (reason: RejectReason) => {
    setAnchorEl(null)
    onReject(reason)
  }

  const renderButton = (title: string, reason: RejectReason, icon: ReactElement) => (
    <ListItem
      beforeTitle={<ListItemIcon>{icon}</ListItemIcon>}
      button
      title={title}
      onClick={() => handleReject(reason)}
    />
  )

  return (
    <>
      <Button onClick={handleClick} variant='contained' className={cx(classes.button, classNames.button)}>
        <Box display='inline-flex' component='span' justifyContent='center' flexGrow={1}>
          {children}
        </Box>
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <PopoverContent BoxProps={{ mb: 1 }}>
          <List>
            {renderButton(
              t('incomingCall.received.rejectReasons.finishingShift'),
              RejectReason.FinishingShift,
              <AccessTime />,
            )}
            {renderButton(t('incomingCall.received.rejectReasons.needBreak'), RejectReason.NeedBreak, <LocalCafe />)}
            {renderButton(t('incomingCall.received.rejectReasons.other'), RejectReason.Other, <Message />)}
          </List>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default RejectButton
