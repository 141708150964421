import { useRef, useEffect } from 'react'

/**
 * Makes given callback cancellable. It isn't called after component is unmounted
 */
const useCancellableCallback = <T extends (...args: any) => any>(
  callback: T,
): ((...args: Parameters<T>) => ReturnType<T> | undefined) => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => { isMounted.current = false; }
  }, [])

  return (...args) => {
    // Unmounted callback is not called, returns undefined instead
    if (!isMounted.current) {
      return
    }

    return callback(...(args as any))
  }
}

export default useCancellableCallback
