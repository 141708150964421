import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const RejectMutation = gql`
  mutation IncomingInvitesReject($invite: InviteInput!) {
    rejectedInvite: rejectInviteFixed(invite: $invite) {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default RejectMutation
