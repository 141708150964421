import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import placeholder from './image/placeholder.png'

declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    loading?: 'lazy' | 'eager'
  }
}

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cover: {
    objectFit: 'cover',
  },
})

type Props = {
  alt: string
  className?: string
  src: string
  width?: number | string
  height?: number | string
  cover?: boolean
}

const Image = ({ src, className, cover, alt, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <img
      className={classnames(classes.root, cover && classes.cover, className)}
      src={src || placeholder}
      loading='lazy'
      alt={alt}
      {...props}
    />
  )
}

export default Image
