import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const SentInvitesOnConfirmed = gql`
  subscription SentInvitesOnConfirmed {
    confirmedInvite: onInviteConfirmed {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default SentInvitesOnConfirmed
