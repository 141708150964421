import { ReactNode, ComponentProps } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Box from '@base/Box'
import Typography from '@base/Typography'
import CloseButton from '@base/CloseButton'
import InfoTitle from '@common/InfoTitle'
import Loading from '@base/Loading'

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  container: {
    height: '100%',
    minHeight: 422,
    padding: spacing(1.25),
    [breakpoints.up('md')]: {
      padding: spacing(2),
    },
  },
  info: {
    lineHeight: 1.25,
  },
}))

type Props = {
  title: string
  boxProps?: ComponentProps<typeof Box>
  info: ReactNode | string
  infoTitleProps: ComponentProps<typeof InfoTitle>
  button: ReactNode
  onClose?: () => void
  loading?: boolean
  error?: string
}

const InviteLayout = ({
  title,
  boxProps,
  info,
  button,
  onClose,
  loading,
  error,
  infoTitleProps: { profile },
}: Props) => {
  const classes = useStyles({})

  return (
    // Relative position so we give position parent to close button
    <Box position='relative' {...boxProps}>
      <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.container}>
        {loading && <Loading />}
        {error && (
          <Typography component='div' variant='body1' align='center'>
            {error}
          </Typography>
        )}
        {profile && <InfoTitle profile={profile} />}
        <Box mt={2} mb={1} color='#52606D'>
          <Typography
            component='div'
            variant='h5'
            weight={600}
            align='center'
            color='inherit'
            data-testid='inviteTitle'
          >
            {title}
          </Typography>
        </Box>
        <Box className={classes.info} mb={2}>
          {info}
        </Box>
        <Box mt={1} textAlign='center' width='100%'>
          {button}
        </Box>
      </Grid>
      {onClose && <CloseButton onClose={onClose} />}
    </Box>
  )
}

export default InviteLayout
