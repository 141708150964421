import gql from 'graphql-tag'

import Message from '@gql/fragments/MessageFragment.api'

export default gql`
  mutation ReplyMessageModalSend($message: MessageInput!) {
    sendMessage(message: $message) {
      ...Message
    }
  }

  ${Message}
`
