import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import NProgress from 'nprogress'
import Router from 'next/router'
import { useEffect } from 'react'

const useStyles = makeStyles(({ palette, zIndex }: Theme) => {
  const color = palette.action.disabled
  const height = 3

  return {
    '@global': {
      nprogress: {
        pointerEvents: 'none',
      },
      '#nprogress .bar': {
        background: color,
        position: 'fixed',
        zIndex: zIndex.modal,
        top: 0,
        left: 0,
        width: '100%',
        height,
      },
      '#nprogress .peg': {
        display: 'block',
        position: 'absolute',
        right: 0,
        width: 100,
        height: '100%',
        boxShadow: `0 0 10px ${color}, 0 0 5px ${color}`,
        opacity: 1,
        transform: `rotate(3deg) translate(0px, -4px)`,
      },
      '#nprogress .spinner': {
        display: 'block',
        position: 'fixed',
        zIndex: 1031,
        top: 15,
        right: 15,
      },
      '#nprogress .spinner-icon': {
        width: 18,
        height: 18,
        boxSizing: 'border-box',
        border: 'solid 2px transparent',
        borderTopColor: color,
        borderLeftColor: color,
        borderRadius: '50%',
        animation: 'nprogress-spinner 400ms linear infinite',
      },
      '.nprogress-custom-parent': {
        overflow: 'hidden',
        position: 'relative',
      },
      '.nprogress-custom-parent #nprogress .spinner': {
        position: 'absolute',
      },
      '.nprogress-custom-parent #nprogress .bar': {
        position: 'absolute',
      },
      '@keyframes nprogress-spinner': {
        [`0%`]: {
          transform: 'rotate(0deg)',
        },
        [`100%`]: {
          transform: 'rotate(360deg)',
        },
      },
    },
  }
})

let timer = null

const ProgressBar = () => {
  const startPosition = 0.3
  const stopDelayMs = 200
  const handleChangeStart = () => {
    NProgress.set(startPosition)
    NProgress.start()
  }

  const handleChangeEnd = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      NProgress.done(true)
    }, stopDelayMs)
  }

  useStyles({})

  useEffect(() => {
    NProgress.configure({ showSpinner: false })
    Router.events.on('routeChangeStart', handleChangeStart)
    Router.events.on('routeChangeComplete', handleChangeEnd)
    Router.events.on('routeChangeError', handleChangeEnd)
  }, [])

  return null
}

export default ProgressBar
