import gql from 'graphql-tag'

import ChatConversationHeaderStudent from './ConversationHeaderStudentFragment.api'
import ChatConversationHeaderTutor from './ConversationHeaderTutorFragment.api'

const ChatConversationHeaderUser = gql`
  fragment ChatConversationHeaderUser on User {
    id
    firstName
    displayName
    image
    currentStatus {
      status
    }
    students {
      ...ChatConversationHeaderStudent
    }
    tutors {
      ...ChatConversationHeaderTutor
    }
  }

  ${ChatConversationHeaderStudent}
  ${ChatConversationHeaderTutor}
`

export default ChatConversationHeaderUser
