import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const SentInvitesOnRejected = gql`
  subscription SentInvitesOnRejected {
    rejectedInvite: onInviteRejected {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default SentInvitesOnRejected
