import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const IncomingInvitesOnCancelled = gql`
  subscription IncomingInvitesOnCancelled {
    cancelledInvite: onInviteCancelled {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default IncomingInvitesOnCancelled
