import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const ConfirmMutation = gql`
  mutation IncomingInvitesConfirm($invite: InviteInput!) {
    confirmedInvite: confirmInviteFixed(invite: $invite) {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default ConfirmMutation
