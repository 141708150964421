import gql from 'graphql-tag'

import InviteParticipant from '../InviteParticipantFragment.api'

export const FragmentName = 'IncomingInvite'

const IncomingInvite = gql`
  fragment IncomingInvite on Invite {
    id
    roomId
    sender
    target
    room {
      id
      callInviteStatus
      createdAt
      duration
      isScheduled
      lesson {
        id
        no
        package {
          id
          name
          lessons {
            id
          }
        }
      }
      participants {
        profileId
        ...InviteParticipant
      }
    }
  }

  ${InviteParticipant}
`

export default IncomingInvite
