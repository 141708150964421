import React, { ReactNode } from 'react'
import cx from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import Lock from '@material-ui/icons/Lock'
import Grid from '@material-ui/core/Grid'

import Typography from '@base/Typography'
import Box from '@base/Box'
import Avatar from '@base/Avatar'
import { getDayName } from '@helpers/dateTime'
import { Status } from '@gql/__generated__'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    cursor: 'pointer',
    position: 'relative',

    '& $name': {
      color: palette.grey[900],
    },

    '& $message': {
      color: palette.grey[700],
    },

    '& $time': {
      color: palette.grey[400],
      fontWeight: 600,
    },
  },
  root__active: {
    background: palette.customBg.linkWater,
    '&:after': {
      background: palette.primary.main,
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      position: 'absolute',
      top: 0,
      width: 4,
    },

    '& $name': {
      color: palette.grey[800],
    },

    '& $message': {
      color: palette.grey[800],
    },

    '& $time': {
      color: palette.grey[800],
      fontWeight: 600,
    },
  },
  avatar: {
    position: 'relative',
  },
  content: {
    overflow: 'hidden',
  },
  name: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  lockIcon: {
    marginBottom: spacing(0.5),
    marginLeft: spacing(0.5),
  },
  time: {
    whiteSpace: 'nowrap',
  },
  message: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  lastCall: {
    color: palette.grey[500],
    fontStyle: 'italic',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

type Props = {
  classes?: {
    root?: string
    name?: string
  }
  controls?: ReactNode
  imageUrl: string
  isActive?: boolean
  isExpired: boolean
  isMessageRead?: boolean
  name: string
  message?: string
  messageSent?: string
  status?: Status
  text?: string
  blockedAt?: Date
}

const ContactItem = ({
  classes = {},
  controls,
  imageUrl,
  isActive = false,
  isExpired,
  isMessageRead,
  message,
  messageSent,
  blockedAt,
  name,
  status,
  text,
}: Props) => {
  const { i18n } = useTranslation()
  const ownClasses = useStyles({})
  const theme: Theme = useTheme()

  return (
    <Box className={cx(ownClasses.root, classes.root, { [ownClasses.root__active]: isActive })} p={2.5}>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item className={ownClasses.avatar}>
          <Avatar size={48} src={imageUrl} />
          {status && (
            <Box
              width={12}
              height={12}
              border={`2px solid ${theme.palette.common.white}`}
              borderRadius='100%'
              position='absolute'
              top={7}
              right={7}
              bgcolor={theme.palette.customCol[status === Status.Offline ? 'alto' : 'mountain']}
            />
          )}
        </Grid>
        <Grid item className={ownClasses.content} xs={true}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={true}>
              <Typography className={cx(ownClasses.name, classes.name)} variant='body1' weight={600}>
                {name} {(isExpired || blockedAt) && <Lock className={ownClasses.lockIcon} fontSize='inherit' />}
              </Typography>
            </Grid>
            {message && messageSent && (
              <Grid item xs='auto'>
                <Typography className={ownClasses.time} variant='caption'>
                  {getDayName(i18n, new Date(messageSent), { dayDate: true })}
                </Typography>
              </Grid>
            )}
          </Grid>
          {message && (
            <Typography className={ownClasses.message} variant='body2' weight={isMessageRead ? 400 : 600}>
              {message}
            </Typography>
          )}
          {text && (
            <Typography className={ownClasses.lastCall} variant='body2'>
              {text}
            </Typography>
          )}
          {controls}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactItem
