import { AnimationState } from './Types'
import { animationActionTypes, AnimationAction, PushAction } from './Actions'

const initialState: AnimationState = {
  flow: {
    previousState: {
      svgName: 'heart',
    },
    currentState: {
      svgName: 'heart',
    },
  },
}

const push = (state: AnimationState, action: PushAction): AnimationState => {
  const previousState = state.flow.currentState
  const { svgName } = action.payload

  return {
    ...state,
    flow: {
      previousState,
      currentState: {
        svgName,
      },
    },
  }
}

const reducer = (state = initialState, action: AnimationAction) => {
  switch (action.type) {
    case animationActionTypes.PUSH:
      return push(state, action as PushAction)
    default:
      return state
  }
}

export default reducer
