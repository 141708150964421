import logo from '@static/favicon/android-chrome-512x512.png'
import Push from 'push.js'
import getLogger from '@src/Logger'

type Props = {
  title: string
  body: string
  tag: string
  requireInteraction?: boolean
}

const showNotification = ({ title, body, tag, requireInteraction }: Props) => {
  Push.create(title, {
    body,
    icon: logo,
    requireInteraction,
    timeout: 20000,
    tag,
    onClick: function () {
      parent.focus()
      window.focus()
      this.close()
    },
  }).catch(err => {
    getLogger().info({ obj: { err } }, 'Push notifications are not permitted')
  })
}

export default showNotification
