import Router from 'next/router'

import { call } from '@api/links'
import getLogger from '@src/Logger'
import useCancellablePromise from '@hooks/useCancellablePromise'

type InviteWithId = {
  roomId: string
  __typename: 'Invite'
}

const useGoToCall = () => {
  const cancellablePromise = useCancellablePromise()

  const goToCall = (invite: InviteWithId): Promise<void> => {
    getLogger().info('Browser navigating call')
    const callLink = call(invite.roomId)
    return cancellablePromise(
      Router.push(callLink.href, callLink.as)
        .then(res => {
          getLogger().info({ res }, `Browser navigation to call ${res ? 'finished' : 'canceled'}`)
          return res
        })
        .catch(err => {
          getLogger().warn({ err }, 'Browser navigation to call failed')
          return false
        }),
    )
  }

  return goToCall
}

export default useGoToCall
