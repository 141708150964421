import { combineReducers } from 'redux'

import userReducer from './modules/User'
import callingReducer from './modules/Calling'
import snackbarReducer from './modules/Snackbar'
import sourceReducer from './modules/Source'
import animationReducer from './modules/Animation'
import { AppState } from './Types'

export default function createReducer(injectedReducers = {}) {
  return combineReducers<AppState>({
    calling: callingReducer,
    user: userReducer,
    snackbar: snackbarReducer,
    source: sourceReducer,
    animation: animationReducer,
    ...injectedReducers,
  })
}
