import getConfig from 'next/config'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { addSeconds } from 'date-fns'

import { packageDetail } from '@api/links'
import Typography from '@base/Typography'
import Link from '@base/Link'
import Box from '@base/Box'
import Button from '@base/Button'
import { IncomingInviteFragment } from '@gql/__generated__'
import { RejectReason } from '@gql/__generated__'
import useIsExpired from '@hooks/useIsExpired'
import useCancellablePromise from '@hooks/useCancellablePromise'
import useCancellableCallback from '@hooks/useCancellableCallback'
import ChatButton from '@components/tutor/ChatButton'
import showNotification from '@helpers/showNotification'
import { formatLocalInterval } from '@helpers/dateTime'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import { Trans, useTranslation } from '@src/i18n'

import Countdown from '../Countdown'
import InviteLayout from '../InviteLayout'
import Progress from '../Progress'
import { getParticipantProfile, getInviteVariant } from '../utils'
import RejectButton from './incomingInvite/RejectButton'
import getLogger from '@src/Logger'

const { CALL_INVITE_EXPIRATION } = getConfig().publicRuntimeConfig

type Props = {
  invite: IncomingInviteFragment
  onConfirm: (IncomingInviteFragment) => Promise<boolean>
  onClose: (IncomingInviteFragment) => Promise<boolean>
  onExpire: (IncomingInviteFragment) => Promise<boolean>
  onReject: (IncomingInviteFragment, reason: RejectReason) => Promise<boolean>
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  accept: {
    color: palette.common.white,
    backgroundColor: palette.customCol.jungle,
    marginRight: 21,
    width: '100%',
    maxWidth: 152,
    '&:hover': {
      backgroundColor: darken(palette.customCol.jungle, 0.15),
    },
  },
  close: {
    minWidth: 150,
  },
  reject: {
    width: '100%',
    maxWidth: 152,
  },
}))

const IncomingInvite = ({ invite, onConfirm, onClose, onExpire, onReject }: Props) => {
  const classes = useStyles({})
  const { i18n, t } = useTranslation()
  const cancellablePromise = useCancellablePromise()
  const [resolving, setResolving] = useState(false)
  const expiresAt = useMemo(() => addSeconds(new Date(invite.room.createdAt), CALL_INVITE_EXPIRATION).toISOString(), [
    invite.room.createdAt,
  ])
  const expireDatePassed = useIsExpired(expiresAt)
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )
  const participant = getParticipantProfile<IncomingInviteFragment>(invite, currentProfileIds)
  const variant = getInviteVariant(invite, true)
  const lesson = invite.room.lesson

  const resolve = (resolveFn: () => Promise<boolean>) => {
    if (resolving) return
    setResolving(true)
    cancellablePromise(resolveFn())
      .then(() => setResolving(false))
      .catch(err => getLogger().error({ err }, 'Error when resolving incoming invite'))
  }

  const handleConfirm = () => {
    resolve(() => onConfirm(invite))
  }

  const handleClose = useCancellableCallback(() => {
    resolve(() => onClose(invite))
  })

  const handleReject = (reason: RejectReason) => {
    resolve(() => onReject(invite, reason))
  }

  const handleExpire = () => {
    resolve(() => onExpire(invite))
  }

  const handleReceive = () => {
    showNotification({
      title: t(`browserNotifications.popup.title`),
      body: t(`browserNotifications.popup.body`, {
        // Fallback to Student if student profile is not retrieved
        name: participant ? participant.user.firstName || participant.user.displayName : 'Student',
      }),
      // don't show multiple notifications for same call
      tag: expiresAt,
      requireInteraction: true,
    })
  }

  useEffect(() => {
    if (variant === 'received') {
      handleReceive()
    }
  }, [variant])

  useEffect(() => {
    // Invite expired based on expiration date but on API it's still not marked as expired
    if (expireDatePassed && variant === 'received') {
      handleExpire()
    }
  }, [expireDatePassed, variant])

  let button
  if (resolving) {
    button = <Progress />
  } else {
    switch (variant) {
      case 'received':
        button = (
          <Grid container justifyContent='center' wrap='nowrap'>
            <Button onClick={handleConfirm} variant='contained' className={classes.accept} data-testid='accept'>
              {t('incomingCall.received.acceptButton')}
            </Button>
            <RejectButton classNames={{ button: classes.reject }} onReject={handleReject}>
              {t('incomingCall.received.rejectButton')}
            </RejectButton>
          </Grid>
        )
        break
      case 'busy':
      case 'expired':
        button = (
          <Grid container spacing={2} justifyContent='center' wrap='nowrap'>
            <Grid item xs={true}>
              <Button color='primary' fullWidth onClick={handleClose} variant='outlined' data-testid='inviteButton'>
                {t(`incomingCall.${variant}.button`)}
              </Button>
            </Grid>
            {participant && (
              <Grid item xs={true}>
                <ChatButton userId={participant.user.id} width='100%' onChat={handleClose} />
              </Grid>
            )}
          </Grid>
        )
        break
      case 'cancelled':
        button = (
          <Grid container spacing={2} justifyContent='center' wrap='nowrap'>
            <Grid item xs={true}>
              <Button
                className={classes.close}
                color='primary'
                variant='outlined'
                onClick={handleClose}
                data-testid='inviteButton'
              >
                {t(`incomingCall.${variant}.button`)}
              </Button>
            </Grid>
            {participant && (
              <Grid item xs={true}>
                <ChatButton userId={participant.user.id} width='100%' onChat={handleClose} />
              </Grid>
            )}
          </Grid>
        )
        break
      default:
        throw new Error('Unhandled invite variant')
    }
  }

  return (
    <InviteLayout
      button={button}
      info={
        <>
          <Box mb={1}>
            <Typography variant='body2' align='center' data-testid='inviteInfo'>
              <Trans
                i18nKey='incomingCall.duration'
                components={[<b key='duration' />]}
                values={{
                  duration: formatLocalInterval(i18n, invite.room.duration),
                }}
              />
            </Typography>
          </Box>
          {lesson && (
            <>
              <Box textAlign='center' mb={1}>
                <Typography variant='body2' weight={600} align='center'>
                  {t('incomingCall.package.title')}:
                </Typography>
                <Tooltip id='package-detail' title={t('incomingCall.package.linkTooltip')}>
                  <div>
                    <Link href={packageDetail(lesson.package.name).as} external={true} variant='body2'>
                      {t(`packages:list.${lesson.package.name}.name`)}
                    </Link>
                  </div>
                </Tooltip>
                <Typography variant='body2' align='center'>
                  {`${t(`packages:list.${lesson.package.name}.calls.${lesson.no - 1}.title`)} (${lesson.no}/${lesson.package.lessons.length
                    })`}
                </Typography>
              </Box>
            </>
          )}
          <Typography variant='body2' align='center'>
            <Trans
              i18nKey={`incomingCall.${variant}.info`}
              values={{
                name: participant ? participant.user.firstName || participant.user.displayName : 'Student',
              }}
              components={[
                variant === 'received' && (
                  <Countdown key='countdown' expireDate={expiresAt} textProps={{ 'data-testid': 'countdown' } as any} />
                ),
              ]}
            />
          </Typography>
        </>
      }
      infoTitleProps={{ profile: participant }}
      title={t(`incomingCall.${variant}.title`)}
    />
  )
}

export default IncomingInvite

export { CALL_INVITE_EXPIRATION }
