import cx from 'classnames'
import { ComponentProps, memo, ReactNode } from 'react'
import Box from '@base/Box'
import Button from '@base/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  button: {
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.26)',
    [breakpoints.up('md')]: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.14)',
    },
  },
}))

type BoxProps = 'color' | 'fontSize' | 'maxWidth' | 'width'

export type Props = Pick<ComponentProps<typeof Box>, BoxProps> &
  Omit<ComponentProps<typeof Button>, BoxProps> & {
    children: ReactNode
    icon?: ReactNode
    loading?: boolean
  }

const ActionButton = memo(
  ({ bgcolor, className, color, fontSize, icon, children, loading, width = '100%', maxWidth, ...props }: Props) => {
    const classes = useStyles({})

    return (
      <Box {...{ width, maxWidth, color }}>
        <Button
          fullWidth
          textColor='inherit'
          bgcolor={bgcolor}
          className={cx(classes.button, className)}
          disabled={loading}
          {...props}
        >
          {loading ? (
            <Box display='flex' mr={1}>
              <CircularProgress color='inherit' size={20} thickness={5} />
            </Box>
          ) : (
            icon
          )}
          <Box component='span' fontSize={fontSize}>
            {children}
          </Box>
        </Button>
      </Box>
    )
  },
)

export default ActionButton
