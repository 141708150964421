import { useEffect, useState } from 'react'
import getLogger from '@src/Logger'

type Props = {
  label?: string
  src: string
  play: boolean
}

const Sound = ({ label = 'Sound', play, src }: Props) => {
  const [audio, setAudio] = useState<HTMLAudioElement>(null)

  useEffect(() => {
    const aud = new Audio(src)
    aud.preload = 'auto'
    aud.loop = true
    setAudio(aud)
  }, [src])

  useEffect(() => {
    if (!audio || !play) {
      return
    }

    const playPromise = audio.paused && audio.play()

    playPromise &&
      playPromise
        .then(() => getLogger().info({ obj: { audioSrc: src } }, `${label} – start playing`))
        .catch(err => getLogger().warn({ obj: { audioSrc: src }, err }, `${label} – not played`))

    return () => {
      playPromise &&
        playPromise
          .then(() => {
            audio.pause()
            audio.currentTime = 0
            getLogger().info({ obj: { audioSrc: src } }, `${label} – stop playing`)
          })
          .catch(err => getLogger().warn({ obj: { audioSrc: src }, err }, `${label} – stop failed`))
    }
  }, [audio, play])

  return null
}

export default Sound
