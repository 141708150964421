import Router from 'next/router'
import { UrlObject } from 'url'

type Url = UrlObject | string

export type BeforeChangeCallback = (method: HistoryMethod, url: Url, as: Url, options?: {}) => boolean

type HistoryMethod = 'replaceState' | 'pushState'

const origRouter = {
  pushState: Router.push,
  replaceState: Router.replace,
}

let callbacks: BeforeChangeCallback[] = []

const change = (method: HistoryMethod, url: Url, as: Url, options?: {}): Promise<boolean> => {
  try {
    const ret = callbacks.reduce((acc, cb) => {
      return acc && cb(method, url, as, options)
    }, true)

    // Intercept if any of registered callbacks returns false
    if (!ret) {
      return Promise.resolve(false)
    }
  } catch (err) {
    return Promise.reject(err)
  }

  return origRouter[method].apply(Router, [url, as, options])
}

/**
 * Intercepts Router's push & replace with given callback
 */
export const onBeforeChange = (callback: BeforeChangeCallback) => {
  callbacks.push(callback)

  const cleanup = () => {
    callbacks = callbacks.filter(cb => cb !== callback)
  }

  if (Router.push === origRouter['pushState']) {
    Router.push = (url: Url, as: Url = url, options = {}) => {
      return change('pushState', url, as, options)
    }
  }

  if (Router.replace === origRouter['replaceState']) {
    Router.replace = (url: Url, as: Url = url, options = {}) => {
      return change('replaceState', url, as, options)
    }
  }

  return cleanup
}
