import { makeStyles, Theme } from '@material-ui/core'
import Tabs, { TabsActions } from '@material-ui/core/Tabs'
import React, { useState, useRef, useEffect } from 'react'

import Box from '@base/Box'
import { IncomingInviteFragment } from '@gql/__generated__'
import { RejectReason } from '@gql/__generated__'
import { useTranslation } from '@src/i18n'

import IncomingInvite from './IncomingInvite'
import IncomingTab from './IncomingTab'
import Container from '../Container'
import { getInviteVariant } from '../utils'

type Props = {
  invites: IncomingInviteFragment[]
  onConfirm: (IncomingInviteFragment) => Promise<boolean>
  onClose: (IncomingInviteFragment) => Promise<boolean>
  onExpire: (IncomingInviteFragment) => Promise<boolean>
  onReject: (IncomingInviteFragment, reason: RejectReason) => Promise<boolean>
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette, shape }) => ({
  tabs: {
    background: '#F5F7FA',
    borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0 0`,
  },
  tabIndicator: {
    backgroundColor: props => (props.selectedInviteMissed ? palette.error.main : palette.primary.main),
    bottom: 'auto',
    top: 0,
  },
}))

type StyleProps = {
  selectedInviteMissed: boolean
}

const IncomingPopover = ({ invites, onConfirm, onClose, onExpire, onReject }: Props) => {
  const action = useRef<TabsActions>(null)
  const [val, setValue] = useState(0)
  const value = Math.min(val, invites.length - 1)
  const { t } = useTranslation()
  const selectedInvite = invites[value]
  const selectedInviteVariant = getInviteVariant(selectedInvite, true)
  const selectedInviteMissed = selectedInviteVariant === 'expired' || selectedInviteVariant === 'cancelled'
  const classes = useStyles({ selectedInviteMissed })

  const handleChange = (_ev, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!action.current) {
      return
    }
    action.current.updateIndicator()
    action.current.updateScrollButtons()
  }, [invites.length])

  if (!invites.length) {
    return null
  }

  return (
    <Container>
      <Tabs
        action={action}
        classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant={invites.length <= 2 ? 'fullWidth' : 'scrollable'}
        scrollButtons='on'
        aria-label={t('incomingCall.tabs')}
      >
        {invites.map(invite => (
          <IncomingTab key={invite.roomId} invite={invite} />
        ))}
      </Tabs>
      {invites.map((invite, index) => (
        // Hidden tabs are still rendered so their lifecycle is continuous
        // This fixes the invite expire-countdown problem
        <Box key={invite.roomId} display={value === index ? 'block' : 'none'}>
          <IncomingInvite
            invite={invite}
            onClose={onClose}
            onConfirm={onConfirm}
            onExpire={onExpire}
            onReject={onReject}
          />
        </Box>
      ))}
    </Container>
  )
}

export default IncomingPopover
