import React, { HTMLAttributes } from 'react'

import useCountdown from '@hooks/useCountdown'
import { formatInterval } from '@helpers/dateTime'

type Props = {
  expireDate: string
  textProps?: HTMLAttributes<HTMLElement>
}

const Countdown = ({ expireDate, textProps }: Props) => {
  const countdown = useCountdown(expireDate)

  return <b {...textProps}>{formatInterval(countdown)}</b>
}

export default Countdown
