import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import throttle from 'lodash/throttle'

import { loadState, saveState } from './localStorage'
import bunyanLoggerMiddleware from './middlewares/BunyanLoggerMiddleware'
import createReducer from './rootReducer'
import getConfig from 'next/config'

let reduxStore = null

const { NODE_CONFIG_ENV } = getConfig().publicRuntimeConfig

const bindMiddleware = middleware => {
  if (NODE_CONFIG_ENV !== 'test' && NODE_CONFIG_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

function configureStore(initialState = {}) {
  const middlewares = [thunkMiddleware, bunyanLoggerMiddleware]
  const store = createStore(
    createReducer(),
    process.browser ? { ...initialState, ...loadState() } : initialState,
    bindMiddleware(middlewares),
  )

  // Persist on change
  store.subscribe(throttle(() => save(store), 1000))
  // Persist initial state
  save(store)

  return store
}

export const save = store => {
  if (process.browser) {
    const state = store.getState()
    saveState({
      source: state.source,
    })
  }
}

export default function makeStore(initialState) {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return configureStore(initialState)
  }

  // Reuse store on the client-side
  if (!reduxStore) {
    reduxStore = configureStore(initialState)
  }

  return reduxStore
}
