import Typography from '@base/Typography'
import Box from '@base/Box'
import React, { ReactNode, ComponentProps } from 'react'
import { Theme, useTheme, Omit } from '@material-ui/core'

type Props = {
  children: ReactNode
  BoxProps?: ComponentProps<typeof Box>
  TextProps?: Omit<ComponentProps<typeof Typography>, 'children'>
}

const TinyAlert = ({ children, BoxProps, TextProps }: Props) => {
  const theme = useTheme<Theme>()

  return (
    <Box
      bgcolor={theme.palette.customCol.sunglow}
      color={theme.palette.text.primary}
      textAlign='center'
      px={2}
      py={0.25}
      {...BoxProps}
    >
      <Typography color='inherit' variant='caption' weight={600} {...TextProps}>
        {children}
      </Typography>
    </Box>
  )
}

export default TinyAlert
