import { showReportDialog } from '@sentry/browser'
import Grid from '@material-ui/core/Grid'

import Box from '@base/Box'
import Button from '@base/Button'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'

type Props = {
  errorEventId: string | null
}

const ErrorScreen = ({ errorEventId }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid container direction='column' alignItems='center' justifyContent='center' style={{ minHeight: '100vh' }}>
      <Box mb={5}>
        <Typography component='h1' data-testid='error-screen-message' variant='h2'>
          {t('base.errorScreen.title')}
        </Typography>
      </Box>
      {errorEventId && (
        <Button color='primary' onClick={() => showReportDialog({ eventId: errorEventId })} variant='contained'>
          {t('base.errorScreen.btnReport')}
        </Button>
      )}
    </Grid>
  )
}

export default ErrorScreen
