import { useApolloClient, useMutation, useQuery, useSubscription } from '@apollo/react-hooks'
import { DataProxy } from 'apollo-cache'
import NoSsr from '@material-ui/core/NoSsr'
import getConfig from 'next/config'
import Router from 'next/router'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { call } from '@api/links'
import Sound from '@base/Sound'
import InviteError from '@components/invite/InviteError'
import {
  CallInviteStatus,
  IncomingInviteFragment,
  IncomingInvitesQuery,
} from '@gql/__generated__'
import {
  IncomingInvitesConfirmMutation,
  IncomingInvitesConfirmMutationVariables,
  IncomingInvitesRejectMutation,
  IncomingInvitesRejectMutationVariables,
  RejectReason,
} from '@gql/__generated__'
import { IncomingInvitesOnCancelledSubscription, IncomingInvitesOnSentSubscription } from '@gql/__generated__'
import ErrorBoundary from '@layouts/ErrorBoundary'
import { AppState } from '@store/Types'
import getLogger from '@src/Logger'
import analytics from '@src/Analytics'
import { userSelectors } from '@store/modules/User'
import { snackbarOperations } from '@store/modules/Snackbar'
import { useTranslation } from '@src/i18n'
import { apiErrorToString } from '@helpers/api'
import Backdrop from '@components/invite/Backdrop'
import createBroadcastChannel, { BroadcastChannel } from '@helpers/broadcastChannel'

import QUERY from './incomingInvites/Query.api'
import CONFIRM from './incomingInvites/ConfirmMutation.api'
import REJECT from './incomingInvites/RejectMutation.api'
import ON_CANCELLED from './incomingInvites/OnCancelledSubscription.api'
import ON_SENT from './incomingInvites/OnSentSubscription.api'
import INCOMING_INVITE, { FragmentName as INCOMING_INVITE_NAME } from './incomingInvites/IncomingInviteFragment.api'
import Head from './incomingInvites/Head'
import {
  readInviteById,
  setInviteFinished,
  setInvitePending,
  setInviteResolved,
  setInviteStatus,
} from './InvitesResolver'
import { deduplicateInvites, getParticipantProfile, sortIncomingInvites } from './utils'
import IncomingPopover from './incomingInvites/IncomingPopover'
import ReplyMessageModal from './incomingInvites/ReplyMessageModal'
import useGoToCall from './useGoToCall'

const { INVITE_NAVIGATION_BLOCKER, INVITE_POLL_INTERVAL } = getConfig().publicRuntimeConfig

const IncomingInvites = () => {
  const isTutor = useSelector<AppState, boolean>(state => userSelectors.isTutor(userSelectors.getSelf(state)))

  if (!isTutor) {
    return null
  }

  return (
    // prevent websocket initialization on server
    <NoSsr>
      <ErrorBoundary component={InviteError}>
        <IncomingInvitesImpl />
      </ErrorBoundary>
    </NoSsr>
  )
}

const IncomingInvitesImpl = () => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const goToCall = useGoToCall()
  const channel = useRef<BroadcastChannel<string>>(null)
  const { t } = useTranslation()
  const { data: { finishedInvites = [], pendingInvites = [] } = {}, refetch } = useQuery<IncomingInvitesQuery>(QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: INVITE_POLL_INTERVAL,
  })
  const [confirmedInvite, setConfirmedInvite] = useState<IncomingInviteFragment>(null)
  const [inviteToReply, setInviteToReply] = useState<IncomingInviteFragment>(null)

  // We deduplicate invites to solve temporary client-server out-of-sync problem (occurs when queries and mutations are racing)
  const invites = useMemo(
    () => sortIncomingInvites<IncomingInviteFragment>(deduplicateInvites([...pendingInvites, ...finishedInvites])),
    [pendingInvites, finishedInvites],
  )
  const currentProfileIds = useSelector<AppState, string[]>(state =>
    userSelectors.getProfileIds(userSelectors.getSelf(state)),
  )

  const handleGoToCall = (invite: IncomingInviteFragment) => {
    goToCall(invite)
      .then(() => setConfirmedInvite(null))
      .catch(err => getLogger().warn({ err }, 'Navigation to call failed'))
    analytics.pushEvent('incoming-call/accepted')
  }

  const handleIncomingInvite = (proxy: DataProxy, invite: IncomingInviteFragment) => {
    getLogger().info({ obj: { invite } }, 'Incoming invite received - subscription ON_INVITE_SENT')

    if (!invite) {
      return
    }

    setInvitePending(proxy, invite.roomId)
    const callLink = call(invite.roomId)
    Router.prefetch(callLink.href, callLink.as).catch(err => getLogger().warn({ err }, 'Browser prefetch failed'))
    analytics.pushEvent('incoming-call')
  }

  const handleCancelledInvite = (proxy: DataProxy, invite: IncomingInviteFragment) => {
    getLogger().info({ obj: { invite } }, 'Incoming invite cancelled - subscription ON_INVITE_CANCELLED')

    if (!invite) {
      return
    }

    // Subscription result sets this Invite field:
    // invite.room.callInviteStatus = "InviteExpired" / "InviteCanceled"
    setInviteFinished(proxy, invite.roomId)
    analytics.pushEvent('incoming-call/cancelled')
  }

  const handleCloseInvite = (invite: IncomingInviteFragment): Promise<boolean> => {
    setInviteResolved(client, invite.roomId)
    return Promise.resolve(true)
  }

  const handleConfirmInvite = (invite: IncomingInviteFragment): Promise<boolean> => {
    const participant = getParticipantProfile(invite, currentProfileIds)
    return confirmInvite({
      variables: {
        invite: {
          profileId: participant.id,
          roomId: invite.roomId,
          expired: false,
        },
      },
      update: (proxy, mutationResult) => {
        getLogger().info(
          { obj: { roomId: invite.roomId, mutationResult } },
          'Invite confirmed - mutation confirmInvite()',
        )

        // Remove invite from pending invites
        setInviteResolved(proxy, invite.roomId)
        setConfirmedInvite(invite)

        // Go to call without navigation blocker (when disabled)
        !INVITE_NAVIGATION_BLOCKER && handleGoToCall(invite)
      },
    })
      .then(() => {
        channel.current &&
          channel.current
            .postMessage('update')
            .then(() => getLogger().info('Posted "invite confirmed" message'))
            .catch(err => getLogger().warn({ err }, 'Posting "invite confirmed" message failed'))
        return true
      })
      .catch(err => {
        getLogger().warn({ err }, 'Invite confirm failed - mutation confirmInvite()')
        dispatch(snackbarOperations.open(apiErrorToString(err), 'error'))
        return false
      })
  }

  const handleExpireInvite = (invite: IncomingInviteFragment): Promise<boolean> => {
    const participant = getParticipantProfile(invite, currentProfileIds)

    // We don't care about the mutation result.
    // Invite is marked expired on BE after given interval so everything is in-sync.
    setInviteStatus(client, invite.roomId, CallInviteStatus.InviteExpired)
    setInviteFinished(client, invite.roomId)

    return rejectInvite({
      variables: {
        invite: {
          profileId: participant.id,
          roomId: invite.roomId,
          expired: true,
        },
      },
      update: (proxy, mutationResult) => {
        // Mutation result sets this Invite field:
        // invite.room.callInviteStatus = "InviteExpired"
        getLogger().info(
          { obj: { mutationResult, roomId: invite.roomId } },
          'Invite expired - mutation rejectInviteFixed()',
        )

        analytics.pushEvent('incoming-call/expired')
      },
    })
      .then(() => true)
      .catch(err => {
        getLogger().warn({ err }, 'Invite expire failed - mutation rejectInviteFixed()')
        return false
      })
  }

  const handleRejectInvite = (invite: IncomingInviteFragment, reason: RejectReason): Promise<boolean> => {
    const participant = getParticipantProfile(invite, currentProfileIds)
    return rejectInvite({
      variables: {
        invite: {
          profileId: participant.id,
          roomId: invite.roomId,
          expired: false,
          reason,
        },
      },
      update: (proxy, mutationResult) => {
        // Mutation result sets this Invite field:
        // invite.room.callInviteStatus = "InviteExpired" / "InviteRejected"
        setInviteResolved(proxy, invite.roomId)
        // In case of "other" reason open Reply modal to send custom message
        if (reason === RejectReason.Other) {
          setInviteToReply(invite)
        }

        getLogger().info(
          { obj: { mutationResult, roomId: invite.roomId } },
          'Invite rejected - mutation rejectInviteFixed()',
        )

        analytics.pushEvent('incoming-call/rejected')
      },
    })
      .then(() => {
        channel.current &&
          channel.current
            .postMessage('update')
            .then(() => getLogger().info('Posted "invite rejected" message'))
            .catch(err => getLogger().warn({ err }, 'Posting "invite rejected" message failed'))
        return true
      })
      .catch(err => {
        getLogger().warn({ err }, 'Invite reject failed - mutation rejectInviteFixed()')
        dispatch(snackbarOperations.open(t('base.error.post'), 'error'))
        return false
      })
  }

  // Handle update on another tab
  const handleUpdate = () => {
    getLogger().info('Incoming invites - Broadcast message update received')
    refetch().catch(err => getLogger().error({ err }, 'Incoming invites refetch failed'))
  }

  const [confirmInvite] = useMutation<IncomingInvitesConfirmMutation, IncomingInvitesConfirmMutationVariables>(CONFIRM)
  const [rejectInvite] = useMutation<IncomingInvitesRejectMutation, IncomingInvitesRejectMutationVariables>(REJECT)

  // Listen to another tab updates
  useEffect(() => {
    const ch = (channel.current = createBroadcastChannel<string>('incomingInvites'))
    ch.addEventListener('message', handleUpdate)
    return () => {
      ch.removeEventListener('message', handleUpdate)
      ch.close().catch(err => getLogger().error({ err }, 'Closing incoming invites channel failed'))
    }
  }, [])

  useEffect(() => {
    if (!pendingInvites.length) {
      return
    }
  }, [pendingInvites.length])

  // Go to call right after UI displays navigation blocker (when enabled)
  // (displays navigation-blocking backdrop)
  useEffect(() => {
    INVITE_NAVIGATION_BLOCKER && confirmedInvite && handleGoToCall(confirmedInvite)
  }, [confirmedInvite?.id])

  useSubscription<IncomingInvitesOnSentSubscription>(ON_SENT, {
    onSubscriptionData: ({ client: ownClient, subscriptionData }) => {
      const { data: { incomingInvite = null } = {} } = subscriptionData
      handleIncomingInvite(ownClient, incomingInvite)
    },
  })

  useSubscription<IncomingInvitesOnCancelledSubscription>(ON_CANCELLED, {
    onSubscriptionData: ({ client: ownClient, subscriptionData }) => {
      const { data: { cancelledInvite = null } = {} } = subscriptionData
      // We receive only partial invite data in subscription, need to read full entity from cache
      const invite = readInviteById<IncomingInviteFragment>(
        ownClient,
        cancelledInvite.roomId,
        INCOMING_INVITE,
        INCOMING_INVITE_NAME,
      )
      handleCancelledInvite(ownClient, invite)
    },
  })

  return (
    <>
      {/* Hide all invites, stop ringing etc. if any invite has been confirmed */}
      {invites.length > 0 && !confirmedInvite && (
        <>
          <Head pendingInvites={pendingInvites} />
          <Sound label='Incoming call ringtone' play={pendingInvites.length > 0} src='/static/audio/ringing.mp3' />
          <IncomingPopover
            invites={invites}
            onClose={handleCloseInvite}
            onConfirm={handleConfirmInvite}
            onExpire={handleExpireInvite}
            onReject={handleRejectInvite}
          />
        </>
      )}
      {inviteToReply && <ReplyMessageModal invite={inviteToReply} onClose={() => setInviteToReply(null)} />}
      <Backdrop open={!!confirmedInvite} />
    </>
  )
}

export default IncomingInvites
