import gql from 'graphql-tag'

const INCOMING_MESSAGE = gql`
  query incomingMessage {
    hasUnreadMessages {
      count
    }
  }
`

export default INCOMING_MESSAGE
