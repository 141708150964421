import { makeStyles } from '@material-ui/core/styles'
import Star from '@material-ui/icons/Star'
import Grid from '@material-ui/core/Grid'

import Typography from '@base/Typography'

const useStyles = makeStyles({
  icon: {
    color: '#EDBB2F',
    marginRight: 13,
  },
})

type Props = {
  value: number
}

const Rating = ({ value }: Props) => {
  const classes = useStyles({})

  return (
    <Grid container alignItems='center' wrap='nowrap'>
      <Star className={classes.icon} fontSize='small' data-testid='ratingIcon' />
      <Typography variant='body1' color='textSecondary' data-testid='rating'>
        {value}
      </Typography>
    </Grid>
  )
}
export default Rating
