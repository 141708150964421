import { ComponentProps } from 'react'
import Button from '@components/base/Button'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'

type Props = ComponentProps<typeof Button> & {
  inProgress: boolean
  progressProps?: CircularProgressProps
}

const ProgressButton = ({ inProgress, children, progressProps, ...props }: Props) => (
  <Button type='submit' disabled={inProgress} {...props}>
    {children}
    {inProgress && '\u00A0\u00A0'}
    {inProgress && <CircularProgress color='inherit' size={20} thickness={5} {...progressProps} />}
  </Button>
)

export default ProgressButton
