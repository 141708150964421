import { useEffect, useState } from 'react'

import { now } from '@lib/withTimeSync'
import { days } from '@helpers/interval'

const getRemainingTime = (until: string | undefined): number => {
  if (typeof until === 'string') {
    return new Date(until).getTime() - now()
  }

  return 0
}

const useIsExpired = (until: string | undefined) => {
  const [isExpired, setExpired] = useState(() => getRemainingTime(until) <= 0)

  useEffect(() => {
    const remainingTime = getRemainingTime(until)
    setExpired(remainingTime <= 0)

    if (remainingTime <= 0) {
      return
    }

    // Set expired to true after 'until' date passes, max 48 h
    const t = setTimeout(() => setExpired(true), Math.min(days(2), remainingTime))

    return () => clearTimeout(t)
  }, [until])

  return isExpired
}

export default useIsExpired
