import { useEffect, useRef } from 'react'
import { useAudio } from 'react-use'

import { Message } from '@gql/__generated__'
import { Priority } from '@gql/__generated__'
import getLogger from '@src/Logger'

const useIncomingMessageAudio = (message: Pick<Message, 'priority'>) => {
  const $lowPlay = useRef<Promise<void>>(Promise.resolve())
  const $highPlay = useRef<Promise<void>>(Promise.resolve())
  const highLoop = useRef<NodeJS.Timeout>(null)

  // Low priority sound
  const [low, _, lowControls] = useAudio({
    src: '/static/audio/message.mp3',
    onPlay: () => getLogger().info('Incoming message – low priority sound is playing'),
    onPause: () => getLogger().info('Incoming message – low priority sound stopped playing'),
  })
  // High priority sound
  const [high, __, highControls, highAudio] = useAudio({
    loop: true,
    src: '/static/audio/highPriority.mp3',
    onPlay: () => getLogger().info('Incoming message – high priority sound is playing'),
    onPause: () => getLogger().info('Incoming message – high priority sound stopped playing'),
  })

  useEffect(() => {
    if (!message) return

    if (message.priority === Priority.High) {
      // Just another high priority message cleans loop of previous high priority message
      // No need to clean it elsewhere as it always loops for 10 seconds
      clearTimeout(highLoop.current)
      highAudio.current.loop = true
      highLoop.current = setTimeout(() => {
        // Check if audio element is still mounted
        if (highAudio.current) {
          highAudio.current.loop = false
        }
      }, 10000)

      $highPlay.current = $highPlay.current.then(() => highControls.play())
    } else {
      $lowPlay.current = $lowPlay.current.then(() => lowControls.play())
    }

    const $play = message.priority === Priority.High ? $highPlay : $lowPlay
    $play.current.catch(err =>
      getLogger().warn({ err }, `Incoming message – ${message.priority} priority sound play failed`),
    )

    const stop = () => {
      $play.current
        .then(() => {
          const controls = message.priority === Priority.High ? highControls : lowControls
          controls.pause()
          controls.seek(0)
        })
        .catch(err => getLogger().warn({ err }, `Incoming message – ${message.priority} priority sound stop failed`))
    }

    return () => {
      // Do not stop high priority sound – it always loops for 10 seconds
      message.priority === Priority.Low && stop()
      // High priority sound stops on unmount because we render `high` component which takes care of it
    }
  }, [message])

  return [low, high]
}

export default useIncomingMessageAudio
