import React, { ComponentProps, ReactNode } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'

import Box from '@base/Box'

import tickBlue from './todoList/tickBlue.svg'

type StyleProps = {
  size: ItemProps['size']
}

const useListStyles = makeStyles({
  list: {
    listStyle: 'none',
    counterReset: 'number',
    padding: 0,
  },
})

const useItemStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  item: (props: StyleProps) => {
    const isSmall = props.size === 'small'
    const isSmallest = props.size === 'smallest'

    return {
      counterIncrement: 'number',
      paddingTop: isSmallest || isSmall ? 60 : 80,
      position: 'relative',
      [breakpoints.up('md')]: {
        paddingTop: 0,
        paddingLeft: isSmallest || isSmall ? 80 : 110,
      },
      '&:before': {
        content: 'counter(number)',
        position: 'absolute',
        color: '#35469C',
        fontWeight: isSmallest || isSmall ? 400 : 600,
        fontSize: isSmallest ? '1rem' : isSmall ? '1.5rem' : '2rem',
        border: `${isSmallest || isSmall ? 3 : 5}px solid ${palette.primary.main}`,
        borderRadius: '50%',
        width: isSmallest ? '2.65rem' : isSmall ? '3rem' : '4.375rem',
        height: isSmallest ? '2.65rem' : isSmall ? '3rem' : '4.375rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 'calc(50% - 35px)',
        [breakpoints.up('md')]: {
          left: 0,
        },
      },
    }
  },

  itemTicked: {
    '&:before': {
      content: `url(${tickBlue}) !important`,
      backgroundColor: '#8EEDD1',
      paddingTop: 6,
    },
  },
}))

type ItemProps = {
  children?: ReactNode | string
  completed: boolean
  boxProps?: ComponentProps<typeof Box>
  size?: 'smallest' | 'small' | 'default'
}

export const TodoItem = ({ children, completed, boxProps, size = 'default' }: ItemProps) => {
  const classes = useItemStyles({ size })

  return (
    <Box component='li' className={cx(classes.item, { [classes.itemTicked]: completed })} {...boxProps}>
      {children}
    </Box>
  )
}

type ListProps = {
  children?: ReactNode
}

const TodoList = ({ children }: ListProps) => {
  const classes = useListStyles({})

  return <ol className={classes.list}>{children}</ol>
}

export default TodoList
