import { ApolloClient } from 'apollo-boost'
import { AppContext } from 'next/app'
import { ParsedUrlQuery } from 'querystring'
import React from 'react'
import { Store } from 'redux'
import { getDataFromTree } from '@apollo/react-ssr'
import Head from 'next/head'
import { Request } from 'express'
import { NextPageContext } from 'next'
import { AppInitialProps } from 'next/app'

import { getCookie, COOKIES } from '@helpers/cookies'
import getLogger from '@src/Logger'
import makeStore from '@store/makeStore'
import { userSelectors } from '@store/modules/User'

import sentry from '../Sentry'
import initApollo from './initApollo'

const { configureScopeFromNextCtx } = sentry()

type Props = {
  apolloClient: ApolloClient<any>
  apolloState: any
}

type AppQuery = ParsedUrlQuery

interface BlabuNextPageContext<Q extends ParsedUrlQuery = ParsedUrlQuery, CustomReq = {}> extends NextPageContext {
  apolloClient: ApolloClient<any>
  store: Store
}

interface WithApolloState<TCache> {
  data?: TCache
}

export default App => {
  return class WithData extends React.Component<Props> {
    private apolloClient: ApolloClient<any>

    static displayName = `WithData(${App.displayName})`

    static async getInitialProps(appCtx: AppContext) {
      const { AppTree, ctx: pageCtx } = appCtx
      const apolloClient = initApollo(
        {},
        {
          ctx: pageCtx,
          // Don't know how to read token from store here. Apollo and Redux HOCs are so much encapsulated.
          // It isn't necessary though. On server request is the source of truth.
          getToken: () => (ctx.req ? (ctx.req as any).token : getCookie(COOKIES.TOKEN)),
        },
      )
      const apolloState: WithApolloState<any> = {}

      const ctx = pageCtx as BlabuNextPageContext<AppQuery, Request>
      ctx.apolloClient = apolloClient

      let appProps: AppInitialProps = { pageProps: {} }
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(appCtx)
      }

      if (ctx.res && (ctx.res.headersSent || ctx.res.finished)) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {}
      }

      if (!process.browser) {
        // Run all graphql queries in the component tree
        // and extract the resulting data
        try {
          // Run all GraphQL queries
          await getDataFromTree(<AppTree {...appProps} apolloClient={apolloClient} apolloState={apolloState} />)
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          configureScopeFromNextCtx(appCtx.ctx)
          getLogger().fatal({ err: error }, 'Error occurred during App.getDataFromTree() phase')
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind()

        // Extract query data from the Apollo's store
        apolloState.data = apolloClient.cache.extract()
      }

      // To avoid calling initApollo() twice in the server we send the Apollo Client as a prop
      // to the component, otherwise the component would have to call initApollo() again but this
      // time without the context, once that happens the following code will make sure we send
      // the prop as `null` to the browser
      // @ts-ignore
      apolloClient.toJSON = () => null

      return {
        ...appProps,
        apolloClient,
        apolloState,
      }
    }

    constructor(props) {
      super(props)
      const { apolloClient, apolloState, initialState } = props
      // We catch Redux store creation here before it is executed in withRedux HOC.
      // We use singleton store factory so it's safe.
      const store = makeStore(initialState)
      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient =
        apolloClient ||
        initApollo(apolloState?.data, {
          getToken: () => {
            const userState = userSelectors.getSelf(store.getState())
            return userSelectors.getToken(userState)
          },
        })
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />
    }
  }
}
