import getConfig from 'next/config'
import { useState } from 'react'
import { useApolloClient, useSubscription } from '@apollo/react-hooks'
import { connect } from 'react-redux'

import { IncomingMessageQuery } from '@gql/__generated__'
import { OnIncomingMessageSubscription } from '@gql/__generated__'
import INCOMING_MESSAGE from '@gql/queries/incomingMessageQuery.api'
import ON_INCOMING_MESSAGE from '@gql/subscriptions/onIncomingMessageSubscription.api'
import IncomingMessageReceived from './IncomingMessageReceived'
import getLogger from '@src/Logger'
import { userSelectors } from '@store/modules/User'
import showNotification from '@helpers/showNotification'
import { useTranslation } from '@src/i18n'

import IncomingMessageAudio from './IncomingMessageAudio'

const { CHAT_NOTIFICATIONS } = getConfig().publicRuntimeConfig

type Props = StateProps

const IncomingMessage = (props: Props) => {
  if (
    // Feature toggle
    !CHAT_NOTIFICATIONS ||
    // Prevent websocket initialization on server
    !process.browser ||
    // Prevent notifications for missing user
    !props.isLoggedIn
  ) {
    return null
  }

  return <IncomingMessageImpl />
}

const IncomingMessageImpl = () => {
  const [message, setMessage] = useState<OnIncomingMessageSubscription['onMessageSent']>(null)
  const client = useApolloClient()
  const { t } = useTranslation()

  useSubscription<OnIncomingMessageSubscription>(ON_INCOMING_MESSAGE, {
    onSubscriptionData: ({ subscriptionData }) => {
      const { data: { onMessageSent: newMessage = null } = {} } = subscriptionData

      if (newMessage && newMessage.conversation) {
        updateUnreadMessages()
        setMessage(newMessage)
        showNotification({
          title: t(`browserNotifications.message.title`, {
            name: newMessage.conversation.user.firstName || newMessage.conversation.user.displayName,
          }),
          body: newMessage.content,
          tag: newMessage.id.toString(),
        })
        getLogger().info({ obj: { message } }, 'Incoming message notification – new message')
      } else {
        const error = new ReferenceError('Incoming message notification – invalid message received')
        getLogger().error({ err: error, obj: { message } }, 'Incoming message notification – invalid message received')
      }
    },
  })

  // Update message count in Apollo cache
  const updateUnreadMessages = () => {
    client
      .query<IncomingMessageQuery>({
        // Always fetch using network
        fetchPolicy: 'network-only',
        query: INCOMING_MESSAGE,
      })
      .catch(() => 'Updating hasUnreadMessages failed')
  }

  return (
    <>
      <IncomingMessageAudio message={message} />
      <IncomingMessageReceived message={message} onClose={() => setMessage(null)} />
    </>
  )
}

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    isLoggedIn: userSelectors.isLoggedIn(userState),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>

const IncomingMessageMapped = connect<StateProps>(mapStateToProps)(IncomingMessage)

export default IncomingMessageMapped
