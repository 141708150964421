import React, { ComponentProps, ReactNode } from 'react'
import { Theme, useTheme } from '@material-ui/core/styles'

import Box from '@base/Box'

type Props = {
  BoxProps?: ComponentProps<typeof Box>
  children: ReactNode
}

const PopoverContent = ({ BoxProps, children }: Props) => {
  const theme: Theme = useTheme()

  return (
    <Box bgcolor='white' border={`1px solid ${theme.palette.grey[300]}`} borderRadius={4} p={1 / 4} {...BoxProps}>
      {children}
    </Box>
  )
}

export default PopoverContent
