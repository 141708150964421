import gql from 'graphql-tag'

const InfoTitleStudent = gql`
  fragment InfoTitleStudent on Student {
    id
    user {
      id
      countryCode
      displayName
      firstName
      image
    }
    rating
  }
`
const InfoTitleTutor = gql`
  fragment InfoTitleTutor on Tutor {
    id
    user {
      id
      countryCode
      displayName
      firstName
      image
    }
    rating
  }
`

export { InfoTitleStudent, InfoTitleTutor }
