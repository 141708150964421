import getConfig from 'next/config'
import App from 'next/app'
import { NextPageContext } from 'next'
import { ApolloProvider } from '@apollo/react-hooks'
import { Provider } from 'react-redux'
import React from 'react'
import Router from 'next/router'
import decode from 'jwt-decode'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import withRedux from 'next-redux-wrapper'
import { ParsedUrlQuery } from 'querystring'
import cuid from 'cuid'
import { appWithTranslation } from '@src/i18n'
import analytics from '@src/Analytics'
import logRocket from '@src/service/LogRocket'
import sentry from '@src/Sentry'
import getLogger, { setLogger } from '@src/Logger'
import ErrorScreen from '@base/ErrorScreen'
import ErrorBoundary from '@layouts/ErrorBoundary'
import makeStore from '@store/makeStore'
import { removeLegacyCookies } from '@helpers/cookies'
import getCurrentUser from '@src/lib/getCurrentUser'
import withAnalytics from '@src/lib/withAnalytics'
import withApollo from '@src/lib/withApollo'
import withTimeSync from '@src/lib/withTimeSync'
import ProgressBar from '@common/ProgressBar'
import Snackbars from '@common/Snackbars'
import Intercom from '@common/Intercom'
import theme from '@lib/theme'
import { findLocale } from '@src/api/locales'
import SET_WEB_LANGUAGE from '@gql/mutations/setWebLanguageMutation.api'
import IncomingMessage from '@components/chat/IncomingMessage'
import { Role, TokenPayload, userOperations, userSelectors } from '@store/modules/User'
import { sourceOperations } from '@store/modules/Source'
import { getUserCurrency } from '@service/Currency'

import SentInvites from '@components/invite/SentInvites'
import IncomingInvites from '@components/invite/IncomingInvites'
import OfflineBar from '@common/OfflineBar'

import UpdateChecker from './_app/UpdateChecker'
import UpdateProfileModal from '@common/UpdateProfileModal'
const { Sentry, captureException, configureScopeFromNextCtx } = sentry()
const { NODE_CONFIG_ENV } = getConfig().publicRuntimeConfig

// FIXME ?? What is this?
// eslint-disable-next-line no-constant-condition
if (NODE_CONFIG_ENV === 'development' && process.browser && false) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, { titleColor: ' #80bd00', diffNameColor: '#ffffff' })
}

// TODO: fix types
type Props = {
  apolloClient: any
  err: NextPageContext['err']
  hasError?: boolean
  errorEventId?: string | null
  store: any
  pathname: string
  pageProps: PageProps
  query: ParsedUrlQuery
}

type PageProps = {
  namespacesRequired?: string[]
}

class MyApp extends App<Props> {
  static async getInitialProps({ Component, ctx }) {
    let pageProps: PageProps = {}
    let tokenPayload: TokenPayload

    configureScopeFromNextCtx(ctx)

    try {
      // Don't run on client, only on server
      if (ctx.req) {
        removeLegacyCookies(ctx)

        if (ctx.req.token) {
          // Store token in Redux
          tokenPayload = decode(ctx.req.token)
          ctx.store.dispatch(userOperations.setToken(ctx.req.token, tokenPayload))

          // Store legacy currentUser object in Redux for logged-in user
          // @TODO remove
          if (tokenPayload.roles.includes(Role.User)) {
            const { id, roles, profileIds, identifier } = tokenPayload
            const { currentUser } = await getCurrentUser(ctx.apolloClient)
            ctx.store.dispatch(
              userOperations.setCurrentUser({ id, roles, profileIds, identifier, ...currentUser }, ctx.req),
            )
          }
        }

        ctx.store.dispatch(sourceOperations.serverInit(ctx))

        if (Component.getInitialProps) {
          pageProps = await Component.getInitialProps(ctx)
        }

        if (ctx.req.i18n && ctx.query.locale) {
          const locale = findLocale(ctx.query.locale)
          if (locale) {
            // Set user's web language
            if (ctx.store.getState().user.currentUser) {
              await ctx.apolloClient.mutate({
                mutation: SET_WEB_LANGUAGE,
                variables: {
                  webLanguage: locale.webLanguage,
                },
              })
            }
          }
        }

        // save currency to apollo store
        ctx.apolloClient.writeData({
          data: { savedCurrency: getUserCurrency(ctx.req.language, ctx) },
        })
      } else if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
      }

      if (!pageProps.namespacesRequired) {
        pageProps.namespacesRequired = ['web', 'packages', 'tutor']
      }

      return {
        pageProps,
        err: ctx.err,
        pathname: ctx.pathname,
        query: ctx.query,
      }

      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
    } catch (error) {
      // Using Bunyan + Sentry here because Bunyan doesn't return Sentry's errorEventId
      const errorEventId = captureException(error)
      getLogger().fatal({ err: error })
      if (ctx === null) {
        getLogger().info('ctx is null, MyApp')
      }

      return {
        hasError: true,
        errorEventId,
        pageProps,
        pathname: ctx.pathname,
        query: ctx.query,
      }
    }
  }

  constructor(props, context: any) {
    super(props, context)
    // Configuring Sentry scope on client hydration (where getInitialProps doesn't get called)
    if (process.browser) {
      const { pathname, query } = props
      configureScopeFromNextCtx({ pathname, query })
    }
  }

  async componentDidMount() {
    const { store } = this.props

    getLogger().info({ obj: { url: window.location && window.location.pathname } }, 'Route loaded')

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode!.removeChild(jssStyles)
    }

    // reset scroll position on route change if not shallow
    Router.events.on('routeChangeComplete', () => {
      if (Router.query.scroll === 'false') return
      'scrollTo' in window && window.scrollTo(0, 0)
    })

    // Create logger with currentUser attributes bounded
    const userState = userSelectors.getSelf(store.getState())
    const isLoggedIn = userSelectors.isLoggedIn(userState)
    const {
      user: { currentUser },
    } = store.getState()
    if (isLoggedIn) {
      const userTokenPayload = userSelectors.getTokenPayload(userState)
      const userToken = userSelectors.getToken(userState)
      const loggedUser = { id: userTokenPayload.id, email: userTokenPayload.identifier, token: userToken }
      // Using Sentry user convention here
      // https://docs.sentry.io/enriching-error-data/context/?platform=node#capturing-the-user
      const instanceFlags = {
        instance: cuid(),
        user: loggedUser,
      }
      setLogger(instanceFlags)
      Sentry.setUser(loggedUser)
      analytics.setUser(currentUser)
      logRocket.identify(userTokenPayload.id, {
        email: userTokenPayload.identifier,
        roles: userTokenPayload.roles.join(','),
      })
    } else {
      setLogger({ instance: cuid() })
      Sentry.setUser(null)
    }

    // Initialize source parameters on app start
    store.dispatch(sourceOperations.clientInit())
  }

  render() {
    const { apolloClient, Component, err, errorEventId, hasError, pageProps, store } = this.props
    const isLoggedIn = userSelectors.isLoggedIn(userSelectors.getSelf(store.getState()))

    return (
      <>
        {/* Wrap every page in Jss and Theme providers */}
        <MuiThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {hasError ? (
            <ErrorScreen errorEventId={errorEventId} />
          ) : (
            <ErrorBoundary component={ErrorScreen}>
              <ApolloProvider client={apolloClient}>
                <Provider store={store}>
                  <OfflineBar />
                  <ProgressBar />
                  {pageProps.showInvites !== false && (
                    <>
                      <SentInvites />
                      <IncomingInvites />
                    </>
                  )}
                  <UpdateChecker show={pageProps.showBars !== false} />
                  <Component {...pageProps} err={err} />
                  <Snackbars />
                  <Intercom hideLauncher={pageProps.showIntercom === false} />
                  {pageProps.showIncomingMessage !== false && <IncomingMessage />}
                  {isLoggedIn && <UpdateProfileModal />}
                </Provider>
              </ApolloProvider>
            </ErrorBoundary>
          )}
        </MuiThemeProvider>
      </>
    )
  }
}

export default withAnalytics()(withTimeSync()(withApollo(withRedux(makeStore)(appWithTranslation(MyApp)))))
