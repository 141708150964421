import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { memo, useEffect, useMemo, useState } from 'react'
import { ReactIntercom } from 'react-intercom/dist/lib/react-intercom'
import { connect, useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import getConfig from 'next/config'

import { WebLanguage } from '@api/locales'
import getLogger from '@src/Logger'
import { getUserImageUrl } from '@helpers/urls'
import { isStandalone } from '@helpers/browser'
import isMobile from '@helpers/isMobile'
import useLocale from '@hooks/useLocale'
import { userSelectors, Role } from '@store/modules/User'
import { IntercomQuery, IntercomQueryVariables, IntercomUserQuery } from '@gql/__generated__'
import { AppState } from '@store/Types'
import { sourceSelectors } from '@store/modules/Source'

import QUERY from './intercom/intercomQuery.api'
import GET_USER from './intercom/getUserQuery.api'

const { INTERCOM } = getConfig().publicRuntimeConfig

const styles = ({ breakpoints, zIndex }: Theme) => {
  // Do not allow Intercom to overlap MUI appbar, drawer, modal etc.
  // (https://material-ui.com/customization/default-theme/)
  const intercomZIndex = zIndex.appBar - 10

  return {
    '@global': {
      '.intercom-namespace iframe.intercom-launcher-frame': {
        // There is not enough space on mobile
        bottom: 87,
        left: 10,
        zIndex: intercomZIndex,
        // Better spacing on tablet and desktop
        [breakpoints.up('sm')]: {
          bottom: 20,
          left: 20,
        },
      },
      '.intercom-namespace  div.intercom-messenger-frame': {
        zIndex: intercomZIndex,
      },
    },
  }
}

type ReduxStateProps = ReturnType<typeof mapStateToProps>

type OwnProps = {
  hideLauncher?: boolean
}

type Props = WithStyles & ReduxStateProps & OwnProps

const Intercom = (props: Props) => {
  // Do not render server-side for performance reasons
  // Also react-intercom doesn't support SSR
  if (typeof window === 'undefined') {
    return null
  }

  return <IntercomImpl {...props} />
}

// For settings see https://www.intercom.com/help/configure-intercom/customize-the-intercom-messenger/customize-the-intercom-messenger-technical
const IntercomImpl = ({ hideLauncher = false, isLoggedIn, userToken }: Props) => {
  const locale = useLocale()
  const referralUrl = useSelector<AppState, string>(state =>
    sourceSelectors.getReferralUrl(sourceSelectors.getSelf(state)),
  )
  const [shouldRender, setShouldRender] = useState(false)
  const { data: intercomData, error: intercomError, loading: intercomLoading } = useQuery<
    IntercomQuery,
    IntercomQueryVariables
  >(QUERY, {
    variables: {
      isLoggedIn,
      isReferral: !!referralUrl,
      referralUrl: referralUrl || '',
    },
    ssr: false,
  })
  // separate to get from cache
  const { data: userData, error: userError, loading: userLoading } = useQuery<IntercomUserQuery>(GET_USER, {
    skip: !isLoggedIn,
    ssr: false,
  })
  const { intercomHash = null, referralUser = null } = intercomData || {}
  const { user = null } = userData || {}
  const loaded = !!intercomData && !intercomLoading && !userLoading && !intercomError && !userError

  const intercomSettings = useMemo(() => {
    const userImage = user && user.image && getUserImageUrl(user.image)
    const intercomUser =
      user && userToken
        ? {
          user_id: userToken.id,
          email: userToken.identifier,
          name: user.firstName ? `${user.firstName} ${user.lastName}` : user.displayName,
          student: userToken.roles.includes(Role.Student),
          tutor: userToken.roles.includes(Role.Tutor),
          avatar: userImage
            ? {
              type: 'avatar',
              image_url: userImage,
            }
            : null,
          user_hash: intercomHash,
        }
        : {}
    return {
      ...intercomUser,
      ReferralUser: referralUser ? referralUser.id : null,
      hide_default_launcher: hideLauncher || (isStandalone() && isMobile({ includeTablets: true })),
    }
  }, [user, userToken, intercomHash, hideLauncher])

  // Do not render ReactIntercom component while data loading for the first time
  // Further on keep ReactIntercom component rendered when data were already loaded once.
  // Otherwise Intercom would be removed anytime when loading is re-triggered (after signup for example)
  useEffect(() => {
    loaded && setShouldRender(true)
  }, [loaded])

  useEffect(() => {
    getLogger().info({ obj: { intercomSettings } }, 'setting intercom')
  }, [intercomSettings])

  if (!shouldRender) {
    return null
  }

  return <IntercomBase intercomSettings={intercomSettings} webLanguage={locale.webLanguage} />
}

type IntercomBaseProps = {
  intercomSettings: any
  webLanguage: WebLanguage
}

/**
 * Do not re-render unless settings has changed. ReactIntercom triggers Intercom('update') on each re-render
 * which is quite expensive call.
 */
// eslint-disable-next-line react/display-name
const IntercomBase = memo(({ intercomSettings, webLanguage }: IntercomBaseProps) => {
  return (
    <ReactIntercom app_id={INTERCOM.appId} alignment='left' language_override={webLanguage} {...intercomSettings} />
  )
})

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    isLoggedIn: userSelectors.isLoggedIn(userState),
    userToken: userSelectors.getTokenPayload(userState),
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const IntercomConnected = connect<ReduxStateProps, {}, OwnProps>(mapStateToProps, null)(Intercom)

export default withStyles(styles)(IntercomConnected)

export { QUERY, GET_USER }
