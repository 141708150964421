import React, { useState, ReactNode, MouseEvent } from 'react'
import Router, { withRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { WithRouterProps } from 'next/dist/client/with-router'
import { makeStyles } from '@material-ui/core/styles'
import Chat from '@material-ui/icons/ChatBubble'

import { userSelectors } from '@store/modules/User'
import getLogger from '@src/Logger'
import { AppState } from '@store/Types'
import links from '@api/links'
import { useCreateChat } from '@tutor/useStartChat'
import { snackbarOperations } from '@store/modules/Snackbar'
import { useTranslation } from '@src/i18n'
import ActionButton from './ActionButton'
import { ButtonProps } from '@material-ui/core/Button'
import UpdateProfileModal from '@common/UpdateProfileModal'
import useCancellablePromise from '@hooks/useCancellablePromise'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
}))

type Props = WithRouterProps &
  ButtonProps & {
    onChat?: () => void
    onClose?: () => void
    userId: string
    width?: number | string
    maxWidth?: number | string
    render?: ({ onClick }: { onClick: (e: MouseEvent) => void }) => ReactNode
  }

const ChatButton = ({ userId, router, onChat, onClose, width, maxWidth, render, ...props }: Props): any => {
  const classes = useStyles({})
  const [isChatRedirecting, setChatRedirecting] = useState(false)
  const [profileCheck, setProfileCheck] = useState(false)
  const { t } = useTranslation()
  const { loading: isChatLoading, createChat } = useCreateChat()
  const loading = isChatLoading || isChatRedirecting
  const cancellablePromise = useCancellablePromise()
  const isLoggedIn = useSelector<AppState, boolean>(state => userSelectors.isLoggedIn(userSelectors.getSelf(state)))
  const isTutor = useSelector<AppState, boolean>(state => userSelectors.isTutor(userSelectors.getSelf(state)))
  const dispatch = useDispatch()

  const handleChatClick = async e => {
    e.preventDefault()
    e.stopPropagation()

    // if tutor modal is opened from conversation, clicking Chat should just close the modal
    if (router.route === '/messages' && onClose) {
      onClose()
      return
    }

    if (!isLoggedIn) {
      setChatRedirecting(true)
      Router.push(links.login.href, links.login.as).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
      return
    }

    if (loading) {
      return
    }
    if (isTutor) {
      redirectToChat()
      return
    }
    // if student doesn't have profile information filled send them to update profile
    setProfileCheck(true)
  }

  const redirectToChat = () => {
    onChat && onChat()
    createChat(userId)
      .then(({ link }) => {
        setChatRedirecting(true)
        cancellablePromise(Router.push(link.href, link.as))
          .then(() => setChatRedirecting(false))
          .catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
      })
      .catch(() => {
        dispatch(snackbarOperations.open(t('chat.start.error.getOrCreateConversationFailed'), 'error'))
      })
  }

  const resolveProfileCheck = () => {
    setProfileCheck(false)
    redirectToChat()
  }

  if (render) return render({ onClick: handleChatClick, ...props })

  return (
    <>
      <ActionButton
        data-testid='chat'
        bgcolor='#35469C'
        color='#FFFFFF'
        icon={<Chat color='inherit' className={classes.icon} />}
        loading={loading}
        onClick={handleChatClick}
        width={width}
        maxWidth={maxWidth}
        {...props}
      >
        {t(`homepage.tutorsOnline.btnChat`)}
      </ActionButton>
      {profileCheck && <UpdateProfileModal onClose={() => setProfileCheck(false)} onResolve={resolveProfileCheck} />}
    </>
  )
}

const ChatButtonMapped = withRouter(ChatButton)

export default ChatButtonMapped
