/* OPERATIONS = REDUX THUNKS / SAGAS...
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator
Complex operations involve returning a thunk that dispatches multiple actions in a certain order
*/
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { LineSvg } from '@components/flow/Line'
import { AppState } from '@src/store/Types'
import * as actions from './Actions'

export const push = (svgName: LineSvg): ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
  const currentState = getState().animation.flow.currentState
  if (currentState.svgName !== svgName) {
    dispatch(actions.push(svgName))
  }
}
