import gql from 'graphql-tag'

// User info displayed near message in message list
const MessageUser = gql`
  fragment MessageUser on User {
    id
    countryCode
    displayName
    firstName
    image
    tutors {
      id
      prices {
        id
        price
        type
      }
      rating
      slug
    }
  }
`

export default MessageUser
