import cx from 'classnames'
import { ReactNode, SyntheticEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import amber from '@material-ui/core/colors/amber'

const iconsByVariant = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

type Props = {
  message: ReactNode
  onClose: (event: SyntheticEvent<any>, reason: string) => void
  variant: 'success' | 'warning' | 'error' | 'info'
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    flexWrap: 'nowrap',
    boxShadow: 'none',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 1,
    marginRight: spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
  },
  text: {
    position: 'relative',
    top: '-1px',
  },
  snackbar__success: {
    backgroundColor: palette.customBg.success,
    color: palette.customCol.success,
  },
  snackbar__error: {
    backgroundColor: palette.customBg.error,
    color: palette.customCol.error,
  },
  snackbar__info: {
    backgroundColor: palette.primary.dark,
  },
  snackbar__warning: {
    backgroundColor: amber[700],
  },
}))

const SnackbarContentWrapper = ({ message, onClose, variant, ...other }: Props) => {
  const Icon = iconsByVariant[variant]
  const classes = useStyles({})

  return (
    <SnackbarContent
      classes={{
        root: cx(classes.root, {
          [classes.snackbar__info]: variant === 'info',
          [classes.snackbar__error]: variant === 'error',
          [classes.snackbar__success]: variant === 'success',
          [classes.snackbar__warning]: variant === 'warning',
        }),
      }}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          <span className={classes.text}>{message}</span>
        </span>
      }
      action={[
        <IconButton key='close' aria-label='Close' color='inherit' onClick={ev => onClose(ev, null)}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

export default SnackbarContentWrapper
