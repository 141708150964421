import { differenceInSeconds } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'

import { now } from '@lib/withTimeSync'

const getTimeLeft = (timestamp: number) => Math.max(0, differenceInSeconds(timestamp, now()))
const shouldTick = (currentTimeLeft: number) => currentTimeLeft > 0

/**
 * Returns remaining time in seconds. Accepts date to count down against
 * - timestamp in miliseconds
 * - date string
 * - undefined for current date
 * It gets re-rendered when date changes so do not pass variable dates like Date.now(). For current date pass undefined.
 */
const useCountdown = (date: number | string | undefined): number => {
  const i = useRef(null)
  const timestamp = useMemo(
    () => (typeof date === 'number' || typeof date === 'string' ? new Date(date).getTime() : now()),
    [date],
  )
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(timestamp))

  useEffect(() => {
    const tick = () => {
      const newTimeLeft = getTimeLeft(timestamp)
      setTimeLeft(newTimeLeft)
      // Prevent unnecessary re-renders
      if (!shouldTick(newTimeLeft)) {
        clearInterval(i.current)
      }
    }

    i.current = setInterval(tick, 1000)
    return () => {
      clearInterval(i.current)
    }
  }, [timestamp])

  return timeLeft
}

export default useCountdown
