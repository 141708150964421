import gql from 'graphql-tag'

import IncomingInvite from './IncomingInviteFragment.api'

const IncomingInvitesOnSent = gql`
  subscription IncomingInvitesOnSent {
    incomingInvite: onInviteSent {
      ...IncomingInvite
    }
  }

  ${IncomingInvite}
`

export default IncomingInvitesOnSent
