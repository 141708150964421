import gql from 'graphql-tag'

import InviteUpdate from '../InviteUpdateFragment.api'

const SentInvitesCancel = gql`
  mutation SentInvitesCancel($profileId: String!, $roomId: String!, $expired: Boolean!) {
    cancelledInvite: cancelInviteFixed(invite: { profileId: $profileId, roomId: $roomId, expired: $expired }) {
      ...InviteUpdate
    }
  }

  ${InviteUpdate}
`

export default SentInvitesCancel
