import gql from 'graphql-tag'

const Conversation = gql`
  fragment Conversation on Conversation {
    id
    expiresAt
    blockedAt
    startedAt
    members {
      userId
    }
    user {
      id
      tutors {
        id
      }
      students {
        id
      }
    }
  }
`

export default Conversation
