import gql from 'graphql-tag'

import SentInvite from './SentInviteFragment.api'

const SentInvitesSend = gql`
  mutation SentInvitesSend($tutorId: String!, $duration: Int!, $lessonId: String) {
    invite: sendInvite(tutorId: $tutorId, duration: $duration, lessonId: $lessonId) {
      ...SentInvite
    }
  }

  ${SentInvite}
`

export default SentInvitesSend
