import { getItem, setItem } from '@helpers/localStorage'

export const loadState = () => {
  try {
    const serializedState = getItem('store')
    if (serializedState === null) {
      return undefined
    }
    // remove legacy keys from store
    const { invite, ...state } = JSON.parse(serializedState)
    return state
  } catch (err) {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    setItem('store', serializedState)
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error(err)
  }
}
