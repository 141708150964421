import React from 'react'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import sentry from '../Sentry'
import getLogger from '@src/Logger'

const { captureException } = sentry()

type ErrorComponentProps = {
  errorEventId: string | null
}

type Props = WithRouterProps & {
  component?: React.ComponentType<ErrorComponentProps>
  render?: (props: ErrorComponentProps) => React.ReactNode
}

type State = {
  hasError: boolean
  errorEventId: string | null
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    errorEventId: null,
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate(prevProps) {
    // When there is an error and location has changed
    if (this.props.router === null) {
      getLogger().info('this.props.router is null, Error Boundary')
    }

    if (prevProps.router === null) {
      getLogger().info('prevProps.router is null, Error Boundary')
    }

    if (this.state.hasError && this.props.router.pathname !== prevProps.router.pathname) {
      // ...then reset error state so page works again when navigated to other page
      this.setState({
        errorEventId: null,
        hasError: false,
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    // Using Bunyan + Sentry here because Bunyan doesn't return Sentry's errorEventId
    const errorEventId = captureException(error, { extra: { ...errorInfo, msg: 'ErrorBoundary.componentDidCatch' } })
    getLogger().error({ err: error, obj: errorInfo }, 'ErrorBoundary.componentDidCatch')

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  render() {
    const { component: Component, render } = this.props
    const { errorEventId, hasError } = this.state

    if (hasError) {
      if (render) {
        return render({ errorEventId })
      }

      if (!Component) return null
      return <Component errorEventId={errorEventId} />
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
