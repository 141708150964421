import { Cache } from 'apollo-cache'
import { InMemoryCache } from 'apollo-boost'
import { getMainDefinition, getOperationName } from 'apollo-utilities'
import { DocumentNode } from 'graphql'

import getLogger from '@src/Logger'

const getQueryDetails = (query: DocumentNode) => {
  const definition = getMainDefinition(query)
  const operationName = getOperationName(query)

  return {
    operationName,
    operationVariables: definition.variableDefinitions,
  }
}

/**
 * Intercepts InMemoryCache calls and logs errors thrown
 */
class LoggerAwareInMemoryCache extends InMemoryCache {
  public read<T>(...args: [Cache.ReadOptions]): T | null {
    try {
      return super.read<T>(...args)
    } catch (err) {
      const options = args[0]
      getLogger().error({ err, obj: getQueryDetails(options.query) }, 'Error when reading from InMemoryCache')
      throw err
    }
  }

  public write(...args: [Cache.WriteOptions]): void {
    try {
      return super.write(...args)
    } catch (err) {
      const options = args[0]
      getLogger().error({ err, obj: getQueryDetails(options.query) }, 'Error when writing to InMemoryCache')
      throw err
    }
  }
}

export default LoggerAwareInMemoryCache
