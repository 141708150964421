import gql from 'graphql-tag'

const AttachmentScheduledCall = gql`
  fragment AttachmentScheduledCall on AttachmentScheduledCall {
    expiresAt
    duration
    hasPassed
    scheduledCallId
    start
  }
`

export default AttachmentScheduledCall
